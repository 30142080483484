import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, Select, MenuItem, Button, Card, CardContent,
    CardActions, IconButton, Pagination, Snackbar, styled, Box,
    TextField, InputAdornment
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import MuiAlert from '@mui/material/Alert';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HistoryIcon from '@mui/icons-material/History';
import LanguageIcon from '@mui/icons-material/Language';
import PublicIcon from '@mui/icons-material/Public';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { motion, AnimatePresence } from 'framer-motion';
import { Fade, Grow, Slide } from '@mui/material';
import axiosInstance from '../api/axiosConfig';
import { useNotification } from './useNotification';
import LoadingIndicator from './LoadingIndicator';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    maxWidth: '1000px',
    margin: '20px auto',
}));

const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
}));

function History() {
    const [posts, setPosts] = useState([]);
    const [platform, setPlatform] = useState('');
    const [language, setLanguage] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { notification, showNotification, hideNotification } = useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchPosts();
    }, [platform, language, startDate, endDate, page, searchTerm]);

    const fetchPosts = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/api/history', {
                params: {
                    platform,
                    language,
                    start_date: startDate ? startDate.toISOString().split('T')[0] : null,
                    end_date: endDate ? endDate.toISOString().split('T')[0] : null,
                    page,
                    search: searchTerm
                }
            });
            setPosts(response.data.posts);
            setTotalPages(response.data.total_pages);
        } catch (error) {
            console.error('Error fetching posts:', error);
            showNotification('Failed to fetch posts. Please try again.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCopy = (content) => {
        navigator.clipboard.writeText(content);
        showNotification('Content copied to clipboard!', 'success');
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setPage(1);
    };

    return (
        (<LocalizationProvider dateAdapter={AdapterDateFns}>
            <Fade in={true} timeout={300}>
                <StyledPaper elevation={3}>
                    <LoadingIndicator open={isLoading} message="Loading posts..." />
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h4" gutterBottom>
                            <HistoryIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                            Generated Posts History
                        </Typography>
                    </Box>

                    <Grow in={true} timeout={300}>
                        <Grid container spacing={2} sx={{ mb: 3 }}>
                            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                                <Select
                                    fullWidth
                                    value={platform}
                                    onChange={(e) => setPlatform(e.target.value)}
                                    displayEmpty
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <PublicIcon />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem value="">All Platforms</MenuItem>
                                    <MenuItem value="Facebook">Facebook</MenuItem>
                                    <MenuItem value="Twitter">Twitter</MenuItem>
                                    <MenuItem value="Instagram">Instagram</MenuItem>
                                </Select>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                                <Select
                                    fullWidth
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                    displayEmpty
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <LanguageIcon />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem value="">All Languages</MenuItem>
                                    <MenuItem value="English">English</MenuItem>
                                    <MenuItem value="Russian">Russian</MenuItem>
                                    <MenuItem value="Azerbaijani">Azerbaijani</MenuItem>
                                </Select>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <DateRangeIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <DateRangeIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                                <TextField
                                    fullWidth
                                    label="Search Posts"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    slotProps={{
                                        input: {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grow>

                    <Slide direction="right" in={true} timeout={300}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<FilterListIcon />}
                            onClick={fetchPosts}
                            sx={{ mb: 3 }}
                        >
                            Apply Filters
                        </Button>
                    </Slide>

                    <AnimatePresence>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <Grid container spacing={2}>
                                {posts.map((post, index) => (
                                    <Grow
                                        in={true}
                                        style={{ transformOrigin: '0 0 0' }}
                                        timeout={300 + index * 100}
                                        key={post.id}
                                    >
                                        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                                            <StyledCard>
                                                <CardContent>
                                                    <Typography variant="h6" gutterBottom>
                                                        <PublicIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                                                        {post.platform} -
                                                        <LanguageIcon sx={{ ml: 1, mr: 1, verticalAlign: 'middle' }} />
                                                        {post.language}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <DateRangeIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                                                        {new Date(post.created_at).toLocaleString()}
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                                                        {post.content}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions sx={{ mt: 'auto' }}>
                                                    <IconButton onClick={() => handleCopy(post.content)} size="small">
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </CardActions>
                                            </StyledCard>
                                        </Grid>
                                    </Grow>
                                ))}
                            </Grid>
                        </motion.div>
                    </AnimatePresence>

                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <StyledPagination
                            count={totalPages}
                            page={page}
                            onChange={(event, value) => setPage(value)}
                        />
                    </motion.div>

                    <Snackbar
                        open={notification.open}
                        autoHideDuration={6000}
                        onClose={hideNotification}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        TransitionComponent={Slide}
                        TransitionProps={{ direction: "down" }}
                    >
                        <MuiAlert onClose={hideNotification} severity={notification.severity} elevation={6} variant="filled">
                            {notification.message}
                        </MuiAlert>
                    </Snackbar>
                </StyledPaper>
            </Fade>
        </LocalizationProvider>)
    );
}

export default History;