import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, Select, MenuItem, Button, Snackbar,
    styled, IconButton, Tooltip, Box, FormControl, Zoom, TextField
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import MuiAlert from '@mui/material/Alert';
import { motion } from 'framer-motion';
import { Fade } from '@mui/material';
import axiosInstance from '../api/axiosConfig';
import { useNotification } from './useNotification';
import SettingsIcon from '@mui/icons-material/Settings';
import LanguageIcon from '@mui/icons-material/Language';
import PublicIcon from '@mui/icons-material/Public';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SaveIcon from '@mui/icons-material/Save';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LoadingIndicator from './LoadingIndicator';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    maxWidth: '800px',
    margin: '20px auto',
    transition: theme.transitions.create(['box-shadow', 'transform'], {
        duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
        boxShadow: theme.shadows[4],
    },
}));

const StyledForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
}));

const StyledSelect = styled(Select)(({ theme }) => ({
    minWidth: 200,
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    transition: theme.transitions.create(['background-color', 'box-shadow'], {
        duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
        boxShadow: theme.shadows[4],
    },
}));

const SettingItem = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

function Settings() {
    const [defaultLanguage, setDefaultLanguage] = useState('');
    const [defaultPlatform, setDefaultPlatform] = useState('');
    const [timezone, setTimezone] = useState('');
    const [timezones, setTimezones] = useState([]);
    const { notification, showNotification, hideNotification } = useNotification();
    const [isLoading, setIsLoading] = useState(true);
    const [facebookAccessToken, setFacebookAccessToken] = useState('');
    const [telegramBotToken, setTelegramBotToken] = useState('');
    const [telegramChatId, setTelegramChatId] = useState('');
    const [facebookLinked, setFacebookLinked] = useState(false);

    useEffect(() => {
        fetchSettings();
        fetchTimezones();
        checkFacebookLinkStatus();
    }, []);

    useEffect(() => {
        // Initialize Facebook SDK
        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : process.env.REACT_APP_FACEBOOK_APP_ID,
                cookie     : true,
                xfbml      : true,
                version    : 'v20.0'
            });

            checkFacebookLoginStatus();
        };

        // Load Facebook SDK
        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }, []);

    const checkFacebookLoginStatus = () => {
        window.FB.getLoginStatus(function(response) {
            setFacebookLinked(response.status === 'connected');
        });
    };

    const checkFacebookLinkStatus = async () => {
        try {
            const response = await axiosInstance.get('/api/user');
            setFacebookLinked(!!response.data.facebook_linked);
        } catch (error) {
            console.error('Error checking Facebook link status:', error);
        }
    };

    const linkFacebookAccount = async (accessToken, userID) => {
        try {
            await axiosInstance.post('/api/facebook-link', {
                accessToken,
                userID
            });
            setFacebookLinked(true);
            showNotification('Facebook account linked successfully', 'success');
        } catch (error) {
            console.error('Error linking Facebook account:', error);
            showNotification('Failed to link Facebook account', 'error');
        }
    };

    const handleFacebookLogin = () => {
        window.FB.login(function(response) {
            if (response.authResponse) {
                const { accessToken, userID } = response.authResponse;
                linkFacebookAccount(accessToken, userID);
            } else {
                console.log('Facebook login failed or was cancelled.');
            }
        }, {scope: 'public_profile,email,pages_show_list,pages_read_engagement,pages_manage_posts'});
    };

    const handleFacebookUnlink = async () => {
        try {
            await axiosInstance.post('/api/facebook-unlink');
            setFacebookLinked(false);
            showNotification('Facebook account unlinked successfully', 'success');
        } catch (error) {
            console.error('Error unlinking Facebook account:', error);
            showNotification('Failed to unlink Facebook account', 'error');
        }
    };

    const fetchTimezones = async () => {
        try {
            const response = await axiosInstance.get('/api/timezones');
            setTimezones(response.data);
        } catch (error) {
            console.error('Error fetching timezones:', error);
            showNotification('Failed to fetch timezones. Please try again.', 'error');
        }
    };

    const fetchSettings = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/api/settings');
            const { default_language, default_platform, timezone, facebook_access_token, telegram_bot_token, telegram_chat_id } = response.data;
            setDefaultLanguage(default_language);
            setDefaultPlatform(default_platform);
            setTimezone(timezone);
            setFacebookAccessToken(facebook_access_token || '');
            setTelegramBotToken(telegram_bot_token || '');
            setTelegramChatId(telegram_chat_id || '');
        } catch (error) {
            console.error('Error fetching settings:', error);
            showNotification('Failed to fetch settings. Please try again.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            await axiosInstance.post('/api/settings', {
                default_language: defaultLanguage,
                default_platform: defaultPlatform,
                timezone: timezone,
                facebook_access_token: facebookAccessToken,
                telegram_bot_token: telegramBotToken,
                telegram_chat_id: telegramChatId
            });
            showNotification('Settings updated successfully', 'success');
        } catch (error) {
            console.error('Error updating settings:', error);
            showNotification('Failed to update settings. Please try again.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Fade in={!isLoading} timeout={300}>
            <StyledPaper elevation={3}>
                <LoadingIndicator open={isLoading} message="Loading settings..." />
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        <SettingsIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                        User Settings
                    </Typography>
                </Box>
                <StyledForm onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Zoom in={!isLoading} style={{ transitionDelay: !isLoading ? '0ms' : '0ms' }}>
                            <SettingItem size={{ xs: 12 }}>
                                <LanguageIcon color="primary" sx={{ mr: 2 }} />
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>Default Language</Typography>
                                <FormControl>
                                    <StyledSelect
                                        value={defaultLanguage}
                                        onChange={(e) => setDefaultLanguage(e.target.value)}
                                        displayEmpty
                                    >
                                        <MenuItem value="English">English</MenuItem>
                                        <MenuItem value="Russian">Russian</MenuItem>
                                        <MenuItem value="Azerbaijani">Azerbaijani</MenuItem>
                                    </StyledSelect>
                                </FormControl>
                                <Tooltip title="Select your preferred language for generated content">
                                    <IconButton size="small" sx={{ ml: 1 }}>
                                        <HelpOutlineIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </SettingItem>
                        </Zoom>
                        <Zoom in={!isLoading} style={{ transitionDelay: !isLoading ? '50ms' : '0ms' }}>
                            <SettingItem size={{ xs: 12 }}>
                                <PublicIcon color="primary" sx={{ mr: 2 }} />
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>Default Platform</Typography>
                                <FormControl>
                                    <StyledSelect
                                        value={defaultPlatform}
                                        onChange={(e) => setDefaultPlatform(e.target.value)}
                                        displayEmpty
                                    >
                                        <MenuItem value="Facebook">Facebook</MenuItem>
                                        <MenuItem value="Twitter">Twitter</MenuItem>
                                        <MenuItem value="Instagram">Instagram</MenuItem>
                                    </StyledSelect>
                                </FormControl>
                                <Tooltip title="Choose your primary social media platform">
                                    <IconButton size="small" sx={{ ml: 1 }}>
                                        <HelpOutlineIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </SettingItem>
                        </Zoom>
                        <Zoom in={!isLoading} style={{ transitionDelay: !isLoading ? '100ms' : '0ms' }}>
                            <SettingItem size={{ xs: 12 }}>
                                <AccessTimeIcon color="primary" sx={{ mr: 2 }} />
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>Timezone</Typography>
                                <FormControl>
                                    <StyledSelect
                                        value={timezone}
                                        onChange={(e) => setTimezone(e.target.value)}
                                        displayEmpty
                                    >
                                        {timezones.map((tz) => (
                                            <MenuItem key={tz} value={tz}>{tz}</MenuItem>
                                        ))}
                                    </StyledSelect>
                                </FormControl>
                                <Tooltip title="Set your local timezone for accurate scheduling">
                                    <IconButton size="small" sx={{ ml: 1 }}>
                                        <HelpOutlineIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </SettingItem>
                        </Zoom>
                        <Zoom in={!isLoading} style={{ transitionDelay: !isLoading ? '150ms' : '0ms' }}>
                            <SettingItem size={{ xs: 12 }}>
                                <FacebookIcon color="primary" sx={{ mr: 2 }} />
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>Facebook Account</Typography>
                                {facebookLinked ? (
                                    <Button variant="outlined" color="secondary" onClick={handleFacebookUnlink}>
                                        Unlink Facebook
                                    </Button>
                                ) : (
                                    <Button variant="contained" color="primary" onClick={handleFacebookLogin}>
                                        Link Facebook Account
                                    </Button>
                                )}
                            </SettingItem>
                        </Zoom>
                        <Zoom in={!isLoading} style={{ transitionDelay: !isLoading ? '200ms' : '0ms' }}>
                            <SettingItem size={{ xs: 12 }}>
                                <TelegramIcon color="primary" sx={{ mr: 2 }} />
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>Telegram Bot Token</Typography>
                                <TextField
                                    type="password"
                                    value={telegramBotToken}
                                    onChange={(e) => setTelegramBotToken(e.target.value)}
                                    placeholder="Enter your Telegram Bot Token"
                                />
                                <Tooltip title="Enter your Telegram Bot Token for posting to Telegram">
                                    <IconButton size="small" sx={{ ml: 1 }}>
                                        <HelpOutlineIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </SettingItem>
                        </Zoom>
                        <Zoom in={!isLoading} style={{ transitionDelay: !isLoading ? '250ms' : '0ms' }}>
                            <SettingItem size={{ xs: 12 }}>
                                <TelegramIcon color="primary" sx={{ mr: 2 }} />
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>Telegram Chat ID</Typography>
                                <TextField
                                    value={telegramChatId}
                                    onChange={(e) => setTelegramChatId(e.target.value)}
                                    placeholder="Enter your Telegram Chat ID"
                                />
                                <Tooltip title="Enter your Telegram Chat ID for posting to Telegram">
                                    <IconButton size="small" sx={{ ml: 1 }}>
                                        <HelpOutlineIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </SettingItem>
                        </Zoom>
                        <Grid size={{ xs: 12 }}>
                            <motion.div
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <AnimatedButton type="submit" variant="contained" color="primary" startIcon={<SaveIcon />}>
                                    Save Settings
                                </AnimatedButton>
                            </motion.div>
                        </Grid>
                    </Grid>
                </StyledForm>
                <Snackbar
                    open={notification.open}
                    autoHideDuration={6000}
                    onClose={hideNotification}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <MuiAlert onClose={hideNotification} severity={notification.severity} elevation={6} variant="filled">
                        {notification.message}
                    </MuiAlert>
                </Snackbar>
            </StyledPaper>
        </Fade>
    );
}

export default Settings;