import { useState } from 'react';

export const useNotification = () => {
    const [notification, setNotification] = useState({
        open: false,
        message: '',
        severity: 'info', // 'error', 'warning', 'info', 'success'
    });

    const showNotification = (message, severity = 'info') => {
        setNotification({
            open: true,
            message,
            severity,
        });
    };

    const hideNotification = () => {
        setNotification((prev) => ({ ...prev, open: false }));
    };

    return {
        notification,
        showNotification,
        hideNotification,
    };
};