import React from 'react';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PostAddIcon from '@mui/icons-material/PostAdd';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const QuickActions = () => {
    const navigate = useNavigate();

    const quickActions = [
        { icon: <PostAddIcon />, name: 'New Post', action: () => navigate('/post_generator') },
        { icon: <InstagramIcon />, name: 'Instagram Post', action: () => navigate('/instagram_post') },
        { icon: <YouTubeIcon />, name: 'YouTube Upload', action: () => navigate('/youtube_upload') },
    ];

    return (
        <SpeedDial
            ariaLabel="Quick Actions"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
        >
            {quickActions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.action}
                />
            ))}
        </SpeedDial>
    );
};

export default QuickActions;