import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, TextField, Button, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Snackbar, styled, IconButton,
    Tooltip, Box, CircularProgress
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { motion, AnimatePresence } from 'framer-motion';
import { Fade, Grow, Slide } from '@mui/material';
import axiosInstance from '../api/axiosConfig';
import { useNotification } from './useNotification';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    maxWidth: '1000px',
    margin: '20px auto',
}));

const StyledForm = styled('form')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(2),
}));

function ScheduledPosts() {
    const [instantPost, setInstantPost] = useState('');
    const [scheduledPosts, setScheduledPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { notification, showNotification, hideNotification } = useNotification();
    const [userTimezone, setUserTimezone] = useState('UTC');

    useEffect(() => {
        fetchScheduledPosts();
        fetchUserTimezone();
    }, []);

    const fetchUserTimezone = async () => {
        try {
            const response = await axiosInstance.get('/api/user_timezone');
            setUserTimezone(response.data.timezone);
        } catch (error) {
            console.error('Error fetching user timezone:', error);
            showNotification('Failed to fetch user timezone. Using UTC.', 'warning');
        }
    };

    const fetchScheduledPosts = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/api/scheduled_posts');
            const posts = Array.isArray(response.data.posts) ? response.data.posts : [];
            setScheduledPosts(posts.map(post => ({
                ...post,
                scheduled_time: utcToZonedTime(parseISO(post.scheduled_time), userTimezone)
            })));
        } catch (error) {
            console.error('Error fetching scheduled posts:', error);
            showNotification('Failed to fetch scheduled posts. Please try again.', 'error');
            setScheduledPosts([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInstantPost = async (event) => {
        event.preventDefault();
        if (!instantPost.trim()) {
            showNotification('Please enter some content for your post.', 'warning');
            return;
        }
        setIsLoading(true);
        try {
            const response = await axiosInstance.post('/api/post_now', { content: instantPost });
            if (response.data.status === 'success') {
                setInstantPost('');
                showNotification('Post published successfully!', 'success');
                fetchScheduledPosts();
            } else {
                throw new Error(response.data.error || 'Failed to publish post');
            }
        } catch (error) {
            console.error('Error posting instantly:', error);
            showNotification(error.message || 'Failed to publish post. Please try again.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditPost = (postId) => {
        // Implement edit functionality
        console.log('Edit post:', postId);
    };

    const handleDeletePost = async (postId) => {
        try {
            await axiosInstance.delete(`/api/scheduled_posts/${postId}`);
            showNotification('Post deleted successfully', 'success');
            fetchScheduledPosts();
        } catch (error) {
            console.error('Error deleting post:', error);
            showNotification('Failed to delete post. Please try again.', 'error');
        }
    };

    return (
        <Fade in={true} timeout={300}>
            <StyledPaper elevation={3}>
                <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h4" gutterBottom>
                        <ScheduleIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                        Scheduled Posts
                    </Typography>
                    <Tooltip title="Refresh posts">
                        <IconButton onClick={fetchScheduledPosts} disabled={isLoading}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Box>

                <Grow in={true} timeout={300}>
                    <StyledForm onSubmit={handleInstantPost}>
                        <Typography variant="h6" gutterBottom>
                            <SendIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                            Post Now
                        </Typography>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            variant="outlined"
                            label="Content"
                            value={instantPost}
                            onChange={(e) => setInstantPost(e.target.value)}
                            sx={{ mb: 2 }}
                            disabled={isLoading}
                        />
                        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                            <StyledButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                startIcon={<SendIcon />}
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Post Now'}
                            </StyledButton>
                        </motion.div>
                    </StyledForm>
                </Grow>

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                >
                    <Typography variant="h6" gutterBottom>
                        <ScheduleIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                        Future Scheduled Posts
                    </Typography>
                    {isLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <StyledTableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Content</StyledTableCell>
                                        <StyledTableCell>Platform</StyledTableCell>
                                        <StyledTableCell>Scheduled Time</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell>Actions</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <AnimatePresence>
                                        {scheduledPosts.map((post) => (
                                            <motion.tr
                                                key={post.id}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0, y: -20 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <StyledTableCell>{post.content}</StyledTableCell>
                                                <StyledTableCell>{post.platform}</StyledTableCell>
                                                <StyledTableCell>
                                                    {format(post.scheduled_time, 'PPpp', { timeZone: userTimezone })}
                                                </StyledTableCell>
                                                <StyledTableCell>{post.status}</StyledTableCell>
                                                <StyledTableCell>
                                                    <Tooltip title="Edit">
                                                        <IconButton onClick={() => handleEditPost(post.id)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <IconButton onClick={() => handleDeletePost(post.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            </motion.tr>
                                        ))}
                                    </AnimatePresence>
                                </TableBody>
                            </Table>
                        </StyledTableContainer>
                    )}
                </motion.div>

                <Box sx={{ mt: 3 }}>
                    <motion.div
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <StyledButton
                            variant="contained"
                            color="primary"
                            startIcon={<AddCircleOutlineIcon />}
                        >
                            Schedule New Post
                        </StyledButton>
                    </motion.div>
                </Box>

                <Snackbar
                    open={notification.open}
                    autoHideDuration={6000}
                    onClose={hideNotification}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    TransitionComponent={Slide}
                    TransitionProps={{ direction: "down" }}
                >
                    <MuiAlert onClose={hideNotification} severity={notification.severity} elevation={6} variant="filled">
                        {notification.message}
                    </MuiAlert>
                </Snackbar>
            </StyledPaper>
        </Fade>
    );
}

export default ScheduledPosts;