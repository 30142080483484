import React, { useState, useEffect } from 'react';
import {
    Paper, Typography, Button, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, Snackbar,
    styled, Box, Tooltip, TextField, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import MuiAlert from '@mui/material/Alert';
import { motion, AnimatePresence } from 'framer-motion';
import { Fade, Grow, Slide } from '@mui/material';
import axiosInstance from '../api/axiosConfig';
import { useNotification } from './useNotification';
import BarChartIcon from '@mui/icons-material/BarChart';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PeopleIcon from '@mui/icons-material/People';
import MouseIcon from '@mui/icons-material/Mouse';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import LoadingIndicator from './LoadingIndicator';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    maxWidth: '1200px',
    margin: '20px auto',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: 'center',
}));

const IconTextBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *:first-child': {
        marginBottom: theme.spacing(1),
    },
}));

function Analytics() {
    const [posts, setPosts] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const { notification, showNotification, hideNotification } = useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');

    useEffect(() => {
        fetchAnalytics();
    }, []);

    const fetchAnalytics = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/api/analytics', {
                params: {
                    start_date: startDate ? startDate.toISOString() : null,
                    end_date: endDate ? endDate.toISOString() : null,
                    sort_by: sortBy,
                    sort_order: sortOrder
                }
            });
            setPosts(response.data.posts);
        } catch (error) {
            console.error('Error fetching analytics:', error);
            showNotification('Failed to fetch analytics. Please try again.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateMetrics = async () => {
        setIsLoading(true);
        try {
            await axiosInstance.post('/api/trigger_update_metrics');
            showNotification('Metrics update triggered successfully', 'success');
            fetchAnalytics();
        } catch (error) {
            console.error('Error updating metrics:', error);
            showNotification('Failed to update metrics. Please try again.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleClearHistory = async () => {
        setIsLoading(true);
        try {
            await axiosInstance.post('/api/clear_post_history');
            showNotification('Post history cleared successfully', 'success');
            setOpenDialog(false);
            fetchAnalytics();
        } catch (error) {
            console.error('Error clearing post history:', error);
            showNotification('Failed to clear post history. Please try again.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSortChange = (event) => {
        setSortBy(event.target.value);
    };

    const handleSortOrderChange = () => {
        setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    };

    const handleApplyFilters = () => {
        fetchAnalytics();
    };

    const chartData = posts.map(post => ({
        name: post.content.substring(0, 20) + '...',
        likes: post.likes_count,
        engagements: post.engagements_count,
        clicks: post.clicks_count,
        reach: post.reach
    }));

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Fade in={true} timeout={300}>
                <StyledPaper elevation={3}>
                    <LoadingIndicator open={isLoading} message="Processing..." />
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h4" gutterBottom>
                            <BarChartIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                            Post Analytics
                        </Typography>
                    </Box>

                    <Grid container spacing={3}>
                        <Grid size={{ xs: 12, md: 8 }}>
                            <Typography variant="h6" gutterBottom>Performance Overview</Typography>
                            <Paper elevation={3} sx={{ p: 2, height: 400 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <ComposedChart data={chartData} margin={{ top: 20, right: 30, bottom: 20, left: 20 }}>
                                        <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
                                        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                                        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                                        <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                                        <RechartsTooltip />
                                        <Legend />
                                        <Bar yAxisId="left" dataKey="likes" fill="#8884d8" name="Likes" />
                                        <Bar yAxisId="left" dataKey="engagements" fill="#82ca9d" name="Engagements" />
                                        <Line yAxisId="right" type="monotone" dataKey="clicks" stroke="#ff7300" name="Clicks" />
                                        <Line yAxisId="right" type="monotone" dataKey="reach" stroke="#413ea0" name="Reach" />
                                        <Brush dataKey="name" height={30} stroke="#8884d8" />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                        <Grid size={{ xs: 12, md: 4 }}>
                            <Typography variant="h6" gutterBottom>Actions</Typography>
                            <Paper elevation={3} sx={{ p: 2 }}>
                                <StyledButton
                                    onClick={handleUpdateMetrics}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    startIcon={<UpdateIcon />}
                                >
                                    Update Metrics Now
                                </StyledButton>
                                <StyledButton
                                    onClick={() => setOpenDialog(true)}
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    startIcon={<DeleteSweepIcon />}
                                >
                                    Clear Post History
                                </StyledButton>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6" gutterBottom>Filters</Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid size={3}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </Grid>
                            <Grid size={3}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </Grid>
                            <Grid size={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Sort By</InputLabel>
                                    <Select
                                        value={sortBy}
                                        onChange={handleSortChange}
                                        label="Sort By"
                                    >
                                        <MenuItem value="likes_count">Likes</MenuItem>
                                        <MenuItem value="engagements_count">Engagements</MenuItem>
                                        <MenuItem value="clicks_count">Clicks</MenuItem>
                                        <MenuItem value="reach">Reach</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSortOrderChange}
                                    fullWidth
                                >
                                    {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
                                </Button>
                            </Grid>
                            <Grid size={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleApplyFilters}
                                    startIcon={<FilterListIcon />}
                                >
                                    Apply Filters
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                    <Grow in={true} timeout={300}>
                        <StyledTableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Post Content</StyledTableCell>
                                        <StyledTableCell>
                                            <IconTextBox>
                                                <AccessTimeIcon />
                                                Posted At
                                            </IconTextBox>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <IconTextBox>
                                                <ThumbUpIcon />
                                                Likes
                                            </IconTextBox>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <IconTextBox>
                                                <PeopleIcon />
                                                Engagements
                                            </IconTextBox>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <IconTextBox>
                                                <MouseIcon />
                                                Clicks
                                            </IconTextBox>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <IconTextBox>
                                                <VisibilityIcon />
                                                Reach
                                            </IconTextBox>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <IconTextBox>
                                                <AccessTimeIcon />
                                                Last Updated
                                            </IconTextBox>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <AnimatePresence>
                                        {posts.map((post, index) => (
                                            <motion.tr
                                                key={post.id}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0, y: -20 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <TableCell>{post.content}</TableCell>
                                                <TableCell>{new Date(post.created_at).toLocaleString()}</TableCell>
                                                <TableCell>{post.likes_count}</TableCell>
                                                <TableCell>{post.engagements_count}</TableCell>
                                                <TableCell>{post.clicks_count}</TableCell>
                                                <TableCell>{post.reach}</TableCell>
                                                <TableCell>{post.last_updated ? new Date(post.last_updated).toLocaleString() : 'N/A'}</TableCell>
                                            </motion.tr>
                                        ))}
                                    </AnimatePresence>
                                </TableBody>
                            </Table>
                        </StyledTableContainer>
                    </Grow>

                    <Dialog
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        TransitionComponent={Slide}
                        TransitionProps={{ direction: "up" }}
                    >
                        <DialogTitle id="alert-dialog-title">
                            <DeleteSweepIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                            {"Clear Post History?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to clear all post history? This action cannot be undone.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDialog(false)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleClearHistory} color="secondary" autoFocus startIcon={<DeleteSweepIcon />}>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        open={notification.open}
                        autoHideDuration={6000}
                        onClose={hideNotification}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        TransitionComponent={Slide}
                        TransitionProps={{ direction: "down" }}
                    >
                        <MuiAlert onClose={hideNotification} severity={notification.severity} elevation={6} variant="filled">
                            {notification.message}
                        </MuiAlert>
                    </Snackbar>
                </StyledPaper>
            </Fade>
        </LocalizationProvider>
    );
}

export default Analytics;