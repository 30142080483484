import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosConfig';
import { useNotification } from './useNotification';
import ForgotPassword from './ForgotPassword';
import { GoogleIcon, FacebookIcon, SitemarkIcon } from './CustomIcons';
import TemplateFrame from './TemplateFrame';
import { useTheme } from '../ThemeContext';
import { Link as RouterLink } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import {useGoogleLogin} from "@react-oauth/google";

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '450px',
    },
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
    height: '100%',
    padding: 20,
    backgroundImage:
        'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
        backgroundImage:
            'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
}));

function Login({ setIsAuthenticated }) {
    const { mode, toggleColorMode } = useTheme();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loginError, setLoginError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
    const [fbError] = useState(null);

    useEffect(() => {
        const rememberedUsername = localStorage.getItem('rememberedUsername');
        if (rememberedUsername) {
            setUsername(rememberedUsername);
            setRememberMe(true);
        }
    }, []);

    const handleForgotPasswordOpen = () => {
        setForgotPasswordOpen(true);
    };

    const handleForgotPasswordClose = () => {
        setForgotPasswordOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validateInputs = () => {
        let isValid = true;

        if (!username) {
            setUsernameError('Username is required');
            isValid = false;
        } else if (username.length < 3) {
            setUsernameError('Username must be at least 3 characters long');
            isValid = false;
        } else {
            setUsernameError('');
        }

        if (!password) {
            setPasswordError('Password is required');
            isValid = false;
        } else if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters long');
            isValid = false;
        } else {
            setPasswordError('');
        }

        return isValid;
    };

    useEffect(() => {
        const loadGoogleScript = () => {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);

            script.onload = initializeGoogleSignIn;
        };

        const initializeGoogleSignIn = () => {
            if (window.google) {
                window.google.accounts.id.initialize({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    callback: handleGoogleSignIn,
                });
            }
        };

        loadGoogleScript();
    }, []);

    useEffect(() => {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : process.env.REACT_APP_FACEBOOK_APP_ID,
                cookie     : true,
                xfbml      : true,
                version    : 'v20.0',
            });

            window.FB.AppEvents.logPageView();

            // Check login status immediately after initialization
            window.FB.getLoginStatus(function(response) {
                console.log("Initial Facebook login status:", response);
            });
        };

        // Load the SDK asynchronously
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }, []);

    function checkLoginState() {
        window.FB.getLoginStatus(function(response) {
            statusChangeCallback(response);
        });
    }

    function statusChangeCallback(response) {
        console.log('statusChangeCallback');
        console.log(response);
        if (response.status === 'connected') {
            // Logged into your webpage and Facebook.
            handleFacebookLoginSuccess(response.authResponse);
        } else {
            // Not logged in
            console.log('User not logged in to Facebook or not authorized');
        }
    }

    const handleFacebookLogin = () => {
        if (!window.FB) {
            console.error('Facebook SDK not loaded');
            showNotification('Facebook login is not available at the moment. Please try again later.', 'error');
            return;
        }

        window.FB.getLoginStatus(function(response) {
            console.log('Initial Facebook status:', response);

            const proceedWithLogin = () => {
                window.FB.login(function(loginResponse) {
                    console.log('Facebook login response:', loginResponse);
                    if (loginResponse.status === 'connected' && loginResponse.authResponse) {
                        const { accessToken, userID } = loginResponse.authResponse;
                        console.log('Facebook login successful', { accessToken, userID });
                        handleFacebookLoginSuccess(accessToken, userID);
                    } else {
                        console.log('Facebook login failed or was cancelled by the user');
                        showNotification('Facebook login was cancelled or failed. Please try again.', 'warning');
                    }
                }, {
                    config_id: '2017187048796464',
                    response_type: 'token',
                    override_default_response_type: true,
                    auth_type: 'rerequest',
                    scope: 'email,public_profile'
                });
            };

            if (response.status === 'connected') {
                console.log('User is already logged in, logging out first');
                window.FB.logout(function(logoutResponse) {
                    console.log('Logout response:', logoutResponse);
                    proceedWithLogin();
                });
            } else {
                console.log('User is not logged in, proceeding with login');
                proceedWithLogin();
            }
        });
    };

    const handleFacebookLoginSuccess = (accessToken, userID) => {
        if (!accessToken) {
            console.error('Missing access token');
            showNotification('Failed to get necessary information from Facebook. Please try again.', 'error');
            return;
        }

        if (!userID) {
            console.log('UserID not provided, fetching from Graph API');
            window.FB.api('/me', { fields: 'id' }, function(response) {
                if (response && response.id) {
                    console.log('Fetched userID:', response.id);
                    proceedWithAuthentication(accessToken, response.id);
                } else {
                    console.error('Failed to fetch userID');
                    showNotification('Failed to get user information from Facebook. Please try again.', 'error');
                }
            });
        } else {
            proceedWithAuthentication(accessToken, userID);
        }
    };

    const proceedWithAuthentication = (accessToken, userID) => {
        console.log('Sending Facebook auth data to server', { accessToken, userID });
        axiosInstance.post('/api/facebook-auth', {
            accessToken,
            userID
        })
            .then(response => {
                console.log('Server response:', response.data);
                if (response.data.access_token && response.data.refresh_token) {
                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('refreshToken', response.data.refresh_token);
                    setIsAuthenticated(true);
                    showNotification('Facebook Sign-in successful!', 'success');
                    navigate('/');
                } else {
                    throw new Error('Invalid server response');
                }
            })
            .catch(error => {
                console.error('Error during Facebook authentication:', error);
                if (error.response) {
                    console.error('Error response:', error.response.data);
                }
                showNotification('Facebook authentication failed. Please try again.', 'error');
            });
    };
    const handleGoogleSignIn = async (response) => {
        try {
            const res = await axiosInstance.post('/api/google-auth', {
                token: response.credential,
            });
            if (res.data.access_token && res.data.refresh_token) {
                localStorage.setItem('token', res.data.access_token);
                localStorage.setItem('refreshToken', res.data.refresh_token);
                axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
                setIsAuthenticated(true);
                showNotification('Google Sign-in successful!', 'success');
                navigate('/');
            }
        } catch (error) {
            console.error('Google Sign-in error:', error);
            showNotification('Google Sign-in failed. Please try again.', 'error');
        }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                const res = await axiosInstance.post('/api/google-auth', {
                    token: response.access_token,
                });
                if (res.data.access_token && res.data.refresh_token) {
                    localStorage.setItem('token', res.data.access_token);
                    localStorage.setItem('refreshToken', res.data.refresh_token);
                    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
                    setIsAuthenticated(true);
                    showNotification('Google Sign-in successful!', 'success');
                    navigate('/');
                }
            } catch (error) {
                console.error('Google Sign-in error:', error);
                showNotification('Google Sign-in failed. Please try again.', 'error');
            }
        },
        onError: () => {
            console.error('Google Sign-In was unsuccessful. Please try again later.');
            showNotification('Google Sign-in failed. Please try again.', 'error');
        },
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoginError(''); // Clear any previous login errors
        if (validateInputs()) {
            setIsLoading(true);
            try {
                const response = await axiosInstance.post('/api/login', { username, password });
                if (response.data.access_token && response.data.refresh_token) {
                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('refreshToken', response.data.refresh_token);

                    if (rememberMe) {
                        localStorage.setItem('rememberedUsername', username);
                    } else {
                        localStorage.removeItem('rememberedUsername');
                    }

                    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;

                    setIsAuthenticated(true);
                    showNotification('Login successful!', 'success');
                    navigate('/');
                } else {
                    throw new Error('Invalid response from server');
                }
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setLoginError('Please activate your account. Check your email for the activation link.');
                } else {
                    setLoginError('Invalid username or password. Please try again.');
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <TemplateFrame toggleColorMode={toggleColorMode} mode={mode}>
            <CssBaseline enableColorScheme />
            <SignInContainer direction="column" justifyContent="space-between">
                <Card variant="outlined">
                    <SitemarkIcon />
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                    >
                        Sign in
                    </Typography>
                    {loginError && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {loginError}
                        </Alert>
                    )}
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                        }}
                    >
                        <FormControl error={!!usernameError}>
                            <FormLabel htmlFor="username">Username</FormLabel>
                            <TextField
                                id="username"
                                type="text"
                                name="username"
                                placeholder="your_username"
                                autoComplete="username"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                error={!!usernameError}
                                helperText={usernameError}
                            />
                        </FormControl>
                        <FormControl error={!!passwordError}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <Link
                                    component="button"
                                    onClick={handleForgotPasswordOpen}
                                    variant="body2"
                                    sx={{ alignSelf: 'baseline' }}
                                >
                                    Forgot your password?
                                </Link>
                            </Box>
                            <TextField
                                name="password"
                                placeholder="••••••"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                required
                                fullWidth
                                variant="outlined"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                error={!!passwordError}
                                helperText={passwordError}
                                slotProps={{
                                    input: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleTogglePasswordVisibility}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={rememberMe}
                                    onChange={(e) => setRememberMe(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Remember me"
                        />
                        <ForgotPassword open={open} handleClose={handleClose} />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            {isLoading ? <CircularProgress size={24} /> : 'Sign in'}
                        </Button>
                        <Typography sx={{ textAlign: 'center' }}>
                            Don't have an account?{' '}
                            <Link component={RouterLink} to="/signup" variant="body2">
                                Sign up
                            </Link>
                        </Typography>
                    </Box>
                    <Divider>or</Divider>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Button
                            onClick={() => googleLogin()}
                            fullWidth
                            variant="outlined"
                            startIcon={<GoogleIcon />}
                        >
                            Sign in with Google
                        </Button>
                        <Button
                            type="button"
                            fullWidth
                            variant="outlined"
                            onClick={handleFacebookLogin}
                            startIcon={<FacebookIcon />}
                        >
                            Sign in with Facebook
                        </Button>
                        {fbError && (
                            <Typography color="error">{fbError}</Typography>
                        )}
                    </Box>
                </Card>
            </SignInContainer>
            <ForgotPassword open={forgotPasswordOpen} handleClose={handleForgotPasswordClose} />
        </TemplateFrame>
    );
}

export default Login;