import React from 'react';
import { CircularProgress, Backdrop, Typography, Box } from '@mui/material';

const LoadingIndicator = ({ open, message }) => {
    return (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                flexDirection: 'column',
            }}
            open={open}
        >
            <CircularProgress color="inherit" size={60} />
            <Box mt={2}>
                <Typography variant="h6">{message || 'Loading...'}</Typography>
            </Box>
        </Backdrop>
    );
};

export default LoadingIndicator;