import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PostAddIcon from '@mui/icons-material/PostAdd';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ScheduleIcon from '@mui/icons-material/Schedule';
import HistoryIcon from '@mui/icons-material/History';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTheme } from '@mui/material/styles';
import { useTheme as useThemeContext } from '../ThemeContext';
import axiosInstance from '../api/axiosConfig';

const drawerWidth = 240;
const collapsedDrawerWidth = 64;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const Drawer = styled('div')(({ theme, open }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: open ? drawerWidth : collapsedDrawerWidth,
    height: '100%',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    '& .MuiListItemText-root': {
        opacity: open ? 1 : 0,
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    '&:hover': {
        cursor: 'pointer',
    },
    '& .MuiListItemText-primary': {
        color: theme.palette.text.primary,
    },
}));

const menuItems = [
    { label: 'Dashboard', path: '/', icon: <DashboardIcon /> },
    { label: 'Post Generator', path: '/post_generator', icon: <PostAddIcon /> },
    { label: 'Instagram Post', path: '/instagram_post', icon: <InstagramIcon /> },
    { label: 'YouTube Upload', path: '/youtube_upload', icon: <YouTubeIcon /> },
    { label: 'Scheduled Posts', path: '/scheduled_posts', icon: <ScheduleIcon /> },
    { label: 'History', path: '/history', icon: <HistoryIcon /> },
    { label: 'Analytics', path: '/analytics', icon: <AnalyticsIcon /> },
    { label: 'Settings', path: '/settings', icon: <SettingsIcon /> },
];

function Header({ isAuthenticated, setIsAuthenticated }) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const { mode, toggleColorMode } = useThemeContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [isFacebookSDKLoaded, setIsFacebookSDKLoaded] = useState(false);

    useEffect(() => {
        // Check if Facebook SDK is loaded
        if (window.FB) {
            setIsFacebookSDKLoaded(true);
        } else {
            const checkFBSDK = setInterval(() => {
                if (window.FB) {
                    setIsFacebookSDKLoaded(true);
                    clearInterval(checkFBSDK);
                }
            }, 100);

            // Clear interval after 10 seconds if SDK is not loaded
            setTimeout(() => clearInterval(checkFBSDK), 10000);
        }
    }, []);

    const handleLogout = () => {
        // Function to perform local logout
        const performLocalLogout = () => {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            setIsAuthenticated(false);
            axiosInstance.defaults.headers.common['Authorization'] = '';
            navigate('/login');
        };

        if (isFacebookSDKLoaded) {
            window.FB.getLoginStatus(function(response) {
                if (response.status === 'connected') {
                    window.FB.logout(function(response) {
                        console.log('Logged out from Facebook');
                        performLocalLogout();
                    });
                } else {
                    performLocalLogout();
                }
            });
        } else {
            console.warn('Facebook SDK not loaded. Proceeding with local logout only.');
            performLocalLogout();
        }
    };

    return (
        <>
            <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        Social Media Post Generator
                    </Typography>
                    <IconButton color="inherit" onClick={toggleColorMode}>
                        {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                open={open}
            >
                <DrawerHeader />
                <List>
                    {menuItems.map((item) => (
                        <Tooltip title={item.label} placement="right" key={item.path}>
                            <StyledListItem
                                component={Link}
                                to={item.path}
                                selected={location.pathname === item.path}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.label} />
                            </StyledListItem>
                        </Tooltip>
                    ))}
                    <Tooltip title="Logout" placement="right">
                        <StyledListItem onClick={handleLogout}>
                            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                            <ListItemText primary="Logout" />
                        </StyledListItem>
                    </Tooltip>
                </List>
            </Drawer>
        </>
    );
}

export default Header;