import React, {useState, useEffect} from 'react';
import {Typography, Paper, Button, Box, CircularProgress, TextField, ButtonGroup, useTheme} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {Link} from 'react-router-dom';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Brush
} from 'recharts';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import axiosInstance from '../api/axiosConfig';
import {useNotification} from './useNotification';
import {subDays, subMonths, startOfYear, subYears} from 'date-fns';

const DashboardCard = ({title, value, link}) => (
    <Paper elevation={3} sx={{p: 2, display: 'flex', flexDirection: 'column', height: 140}}>
        <Typography variant="h6" gutterBottom>{title}</Typography>
        <Typography variant="h4" component="div" sx={{flexGrow: 1}}>{value}</Typography>
        <Button component={Link} to={link} size="small">View Details</Button>
    </Paper>
);

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const {showNotification} = useNotification();
    const [startDate, setStartDate] = useState(subDays(new Date(), 7));
    const [endDate, setEndDate] = useState(new Date());
    const [activePreset, setActivePreset] = useState('lastWeek');
    const [viewType, setViewType] = useState('day');
    const [focusBar, setFocusBar] = useState(null);
    const theme = useTheme();

    const fetchDashboardData = async () => {
        try {
            const response = await axiosInstance.get('/api/dashboard', {
                params: {
                    start_date: startDate.toISOString(),
                    end_date: endDate.toISOString(),
                    view_type: viewType // Include view type in the API call
                }
            });
            setDashboardData(response.data);
            setIsLoading(false);
        } catch (err) {
            setError('Failed to fetch dashboard data');
            showNotification('Failed to fetch dashboard data', 'error');
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDashboardData();

        const intervalId = setInterval(() => {
            fetchDashboardData();
        }, 30000);

        return () => clearInterval(intervalId);
    }, [startDate, endDate, viewType]); // Include viewType in the dependency array

    const handleViewTypeChange = (newViewType) => {
        setViewType(newViewType);
    };

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <Paper elevation={3} sx={{p: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)'}}>
                    <Typography variant="subtitle2" color="textSecondary">{label}</Typography>
                    {payload.map((entry, index) => (
                        <Typography key={index} variant="body2" color={entry.color}>
                            {`${entry.name}: ${entry.value}`}
                        </Typography>
                    ))}
                </Paper>
            );
        }
        return null;
    };

    const handleStartDateChange = (newDate) => {
        setStartDate(newDate);
        setActivePreset(null);
    };

    const handleEndDateChange = (newDate) => {
        setEndDate(newDate);
        setActivePreset(null);
    };

    const handlePresetChange = (preset) => {
        const today = new Date();
        setActivePreset(preset);
        switch (preset) {
            case 'today':
                setStartDate(today);
                setEndDate(today);
                break;
            case 'lastWeek':
                setStartDate(subDays(today, 7));
                setEndDate(today);
                break;
            case 'lastMonth':
                setStartDate(subMonths(today, 1));
                setEndDate(today);
                break;
            case 'thisYear':
                setStartDate(startOfYear(today));
                setEndDate(today);
                break;
            case 'lastYear':
                setStartDate(subYears(today, 1));
                setEndDate(today);
                break;
            default:
                break;
        }
    };
    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress/>
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{p: 3}}>
                <Typography variant="h6" color="error" gutterBottom>
                    {error}
                </Typography>
                <Button variant="contained" onClick={fetchDashboardData}>
                    Retry
                </Button>
            </Box>
        );
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{flexGrow: 1, p: 3}}>
                <Typography variant="h4" gutterBottom>Dashboard</Typography>
                <Box sx={{mb: 3}}>
                    <ButtonGroup variant="outlined" sx={{mb: 2}}>
                        {[
                            {key: 'today', label: 'Today'},
                            {key: 'lastWeek', label: 'Last 7 Days'},
                            {key: 'lastMonth', label: 'Last 30 Days'},
                            {key: 'lastYear', label: 'Last 365 Days'},
                            {key: 'thisYear', label: 'This Year'},
                        ].map((preset) => (
                            <Button
                                key={preset.key}
                                onClick={() => handlePresetChange(preset.key)}
                                variant={activePreset === preset.key ? 'contained' : 'outlined'}
                                sx={{
                                    backgroundColor: activePreset === preset.key ? 'primary.main' : 'transparent',
                                    color: activePreset === preset.key ? 'primary.contrastText' : 'primary.main',
                                    '&:hover': {
                                        backgroundColor: activePreset === preset.key ? 'primary.dark' : 'primary.light',
                                    },
                                }}
                            >
                                {preset.label}
                            </Button>
                        ))}
                    </ButtonGroup>
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            maxDate={endDate}
                        />
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            minDate={startDate}
                            maxDate={new Date()} // Disable future dates
                        />
                    </Box>
                    <ButtonGroup variant="outlined" sx={{mt: 2}}>
                        {[
                            {key: 'hour', label: 'Hourly'},
                            {key: 'day', label: 'Daily'},
                            {key: 'month', label: 'Monthly'},
                        ].map((view) => (
                            <Button
                                key={view.key}
                                onClick={() => handleViewTypeChange(view.key)}
                                variant={viewType === view.key ? 'contained' : 'outlined'}
                                sx={{
                                    backgroundColor: viewType === view.key ? 'primary.main' : 'transparent',
                                    color: viewType === view.key ? 'primary.contrastText' : 'primary.main',
                                    '&:hover': {
                                        backgroundColor: viewType === view.key ? 'primary.dark' : 'primary.light',
                                    },
                                }}
                            >
                                {view.label}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Box>
                <Grid container spacing={3}>
                    <Grid size={{xs: 12, md: 4}}>
                        <DashboardCard
                            title="Total Posts"
                            value={dashboardData?.totalPosts || 0}
                            link="/history"
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 4}}>
                        <DashboardCard
                            title="Scheduled Posts"
                            value={dashboardData?.scheduledPosts || 0}
                            link="/scheduled_posts"
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 4}}>
                        <DashboardCard
                            title="Total Engagements"
                            value={dashboardData?.totalEngagements || 0}
                            link="/analytics"
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Paper elevation={3} sx={{p: 2, height: 500}}>
                            <Typography variant="h6" gutterBottom>Post Performance</Typography>
                            <ResponsiveContainer width="100%" height="100%">
                                <ComposedChart
                                    data={dashboardData?.chartData || []}
                                    margin={{top: 20, right: 30, bottom: 20, left: 20}}
                                    onMouseMove={(state) => {
                                        if (state.isTooltipActive) {
                                            setFocusBar(state.activeTooltipIndex);
                                        } else {
                                            setFocusBar(null);
                                        }
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider}/>
                                    <XAxis
                                        dataKey="name"
                                        scale="point"
                                        padding={{left: 10, right: 10}}
                                        tick={{fill: theme.palette.text.secondary, fontSize: 12}}
                                    />
                                    <YAxis
                                        yAxisId="left"
                                        orientation="left"
                                        stroke={theme.palette.primary.main}
                                        label={{
                                            value: 'Posts',
                                            angle: -90,
                                            position: 'insideLeft',
                                            fill: theme.palette.text.primary
                                        }}
                                    />
                                    <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                        stroke={theme.palette.secondary.main}
                                        label={{
                                            value: 'Engagements',
                                            angle: 90,
                                            position: 'insideRight',
                                            fill: theme.palette.text.primary
                                        }}
                                    />
                                    <Tooltip content={<CustomTooltip/>}/>
                                    <Legend wrapperStyle={{paddingTop: '20px'}}/>
                                    <Bar
                                        dataKey="posts"
                                        yAxisId="left"
                                        fill={theme.palette.primary.main}
                                        radius={[4, 4, 0, 0]}
                                        name="Posts"
                                        barSize={20}
                                        opacity={({index}) => focusBar === index ? 1 : 0.6}
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey="engagements"
                                        yAxisId="right"
                                        stroke={theme.palette.secondary.main}
                                        strokeWidth={3}
                                        dot={{r: 4}}
                                        activeDot={{r: 8}}
                                        name="Engagements"
                                    />
                                    <Brush
                                        dataKey="name"
                                        height={30}
                                        stroke={theme.palette.primary.light}
                                        fill={theme.palette.background.paper}
                                    />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
};

export default Dashboard;