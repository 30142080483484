import React, {useState, useEffect} from 'react';
import {
    TextField, Button, Paper, Typography, Card, CardContent, CardActions,
    Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, styled,
    IconButton, FormControl, FormLabel, FormControlLabel, Checkbox, Select,
    MenuItem, RadioGroup, Radio, Fade, Grow, Slide, ListItemText, InputLabel,
    Accordion, AccordionSummary, AccordionDetails, Box
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {motion, AnimatePresence} from 'framer-motion';
import MuiAlert from '@mui/material/Alert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axiosInstance from '../api/axiosConfig';
import {useNotification} from './useNotification';
import {StaticDateTimePicker} from '@mui/x-date-pickers/StaticDateTimePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArticleIcon from '@mui/icons-material/Article';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SendIcon from '@mui/icons-material/Send';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import EmptyStateIllustration from './EmptyStateIllustration';
import LoadingIndicator from './LoadingIndicator';
import {useNavigate} from 'react-router-dom';
import {utcToZonedTime, format} from 'date-fns-tz'


const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(3),
    maxWidth: '800px',
    margin: '20px auto',
}));

const StyledForm = styled('form')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
}));

const StyledButton = styled(Button)(({theme}) => ({
    marginTop: theme.spacing(2),
}));

const StyledCard = styled(Card)(({theme}) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));

const TONES = [
    'Analytical', 'Apologetic', 'Assertive', 'Authoritative', 'Avant-garde',
    'Casual', 'Cautionary', 'Celebratory', 'Compassionate', 'Confident',
    'Congratulatory', 'Controversial', 'Conversational', 'Curious', 'Cynical',
    'Dramatic', 'Educational', 'Empathetic', 'Encouraging', 'Enigmatic',
    'Enthusiastic', 'Ethereal', 'Excited', 'Formal', 'Friendly',
    'Futuristic', 'Grateful', 'Hopeful', 'Humble', 'Humorous',
    'Informative', 'Inspirational', 'Introspective', 'Ironic', 'Irreverent',
    'Melancholic', 'Motivational', 'Mysterious', 'Neutral', 'Nostalgic',
    'Optimistic', 'Patriotic', 'Persuasive', 'Pessimistic', 'Philosophical',
    'Poetic', 'Professional', 'Provocative', 'Rebellious', 'Reflective',
    'Romantic', 'Sarcastic', 'Satirical', 'Sensational', 'Sensual',
    'Serious', 'Shocked', 'Skeptical', 'Stoic', 'Sympathetic',
    'Urgent', 'Whimsical', 'Witty', 'Zen'
];

function PostGenerator() {
    const [articleUrl, setArticleUrl] = useState('');
    const [article, setArticle] = useState('');
    const [languages, setLanguages] = useState([]);
    const [platform, setPlatform] = useState('');
    const [tones, setTones] = useState([]);
    const [generatedPosts, setGeneratedPosts] = useState([]);
    const {notification, showNotification, hideNotification} = useNotification();
    const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
    const [scheduledDate, setScheduledDate] = useState(new Date());
    const [selectedPostId, setSelectedPostId] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [copySuccess, setCopySuccess] = useState('');
    const [defaultSettings, setDefaultSettings] = useState({});
    const [isLoading, setIsLoading] = useState(false); // Add this line
    const navigate = useNavigate();
    const [instantPostContent, setInstantPostContent] = useState('');
    const [userTimezone, setUserTimezone] = useState('UTC');
    const [facebookPages, setFacebookPages] = useState([]);
    const [selectedPage, setSelectedPage] = useState('');

    useEffect(() => {
        fetchUserTimezone();
    }, []);

    useEffect(() => {
        fetchUserSettings();
    }, []);

    useEffect(() => {
        fetchFacebookPages();
    }, []);

    const fetchUserTimezone = async () => {
        try {
            const response = await axiosInstance.get('/api/user_timezone');
            setUserTimezone(response.data.timezone);
        } catch (error) {
            console.error('Error fetching user timezone:', error);
            showNotification('Failed to fetch user timezone. Using UTC.', 'warning');
        }
    };

    const fetchUserSettings = async () => {
        try {
            const response = await axiosInstance.get('/api/settings');
            if (response.data && response.data.default_platform && response.data.default_language) {
                setDefaultSettings(response.data);
                // Set initial values for platform and languages
                setPlatform(response.data.default_platform);
                setLanguages([response.data.default_language]);
            } else {
                console.log('User settings not available or incomplete');
                // Set default values if user settings are not available
                setPlatform('Facebook');  // or whatever default you prefer
                setLanguages(['English']);  // or whatever default you prefer
            }
        } catch (error) {
            console.error('Error fetching user settings:', error);
            showNotification('Failed to fetch user settings', 'error');
            // Set default values here as well
            setPlatform('Facebook');
            setLanguages(['English']);
        }
    };

    const fetchFacebookPages = async () => {
        try {
            const response = await axiosInstance.get('/api/facebook_pages');
            console.log('Fetched Facebook pages:', response.data.pages);
            setFacebookPages(response.data.pages);
        } catch (error) {
            console.error('Error fetching Facebook pages:', error);
            showNotification('Failed to fetch Facebook pages', 'error');
        }
    };

    const handleCopyToClipboard = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopySuccess('Copied!');
            setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
        }, (err) => {
            console.error('Failed to copy text: ', err);
            setCopySuccess('Copy failed');
        });
    };

    const handleExtractArticle = async () => {
        setIsLoading(true); // Add this line
        try {
            const response = await axiosInstance.post('/api/extract_article', {url: articleUrl});
            setArticle(response.data.article_text);
            showNotification('Article extracted successfully', 'success');
        } catch (error) {
            console.error('Error extracting article:', error);
            showNotification('Failed to extract article. Please try again.', 'error');
        } finally {
            setIsLoading(false); // Add this line
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await axiosInstance.post('/api/generate_posts', {
                article,
                languages,
                platform,
                tones,
                article_url: articleUrl
            });
            setGeneratedPosts(response.data);
            showNotification('Posts generated successfully', 'success');
        } catch (error) {
            console.error('Error generating posts:', error);
            showNotification('Failed to generate posts. Please try again.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePostNow = async (postId) => {
        setIsLoading(true);
        try {
            const post = generatedPosts.find(p => p.id === postId);
            if (!post) {
                throw new Error('Post not found');
            }
            const response = await axiosInstance.post(`/api/post_now/${postId}`, {
                pageId: selectedPage
            });

            if (response.data.status === 'success') {
                showNotification('Post published successfully!', 'success');
                setGeneratedPosts(prevPosts =>
                    prevPosts.map(post =>
                        post.id === postId ? {...post, status: 'posted'} : post
                    )
                );
            } else if (response.data.status === 'permission_error') {
                showNotification('Insufficient Facebook permissions. Please reconnect your account in the settings.', 'error');
                // Optionally, you can redirect the user to the settings page or show a dialog to reconnect Facebook
            } else {
                throw new Error(response.data.error || 'Unknown error occurred');
            }
        } catch (error) {
            console.error('Error posting:', error);
            showNotification(`Failed to publish post: ${error.message}`, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSchedulePost = async (postId) => {
        setSelectedPostId(postId);
        setScheduleDialogOpen(true);
    };

    const handleCloseScheduleDialog = () => {
        setScheduleDialogOpen(false);
        setSelectedPostId(null);
    };

    const handleConfirmSchedule = async () => {
        try {
            const localDate = scheduledDate;
            const formattedDate = format(localDate, "yyyy-MM-dd'T'HH:mm:ss.SSS");

            const response = await axiosInstance.post(`/api/schedule_post/${selectedPostId}`, {
                scheduled_time: formattedDate
            });
            showNotification('Post scheduled successfully!', 'success');
            setGeneratedPosts(prevPosts =>
                prevPosts.map(post =>
                    post.id === selectedPostId ? {...post, status: 'scheduled'} : post
                )
            );
        } catch (error) {
            console.error('Error scheduling post:', error);
            showNotification('Failed to schedule post. Please try again.', 'error');
        }
        handleCloseScheduleDialog();
    };

    return (
        (<LocalizationProvider dateAdapter={AdapterDateFns}>
            <Fade in={true} timeout={300}>
                <StyledPaper elevation={3}>
                    <LoadingIndicator open={isLoading} message="Processing..."/>
                    <Box sx={{mb: 4}}>
                        <Typography variant="h4" gutterBottom>
                            <PostAddIcon sx={{mr: 1, verticalAlign: 'middle'}}/>
                            Social Media Post Generator
                        </Typography>
                    </Box>

                    <StyledForm onSubmit={handleSubmit}>
                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant="h6">Article Input</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid size={{xs: 12}}>
                                        <TextField
                                            fullWidth
                                            label="Article URL"
                                            value={articleUrl}
                                            onChange={(e) => setArticleUrl(e.target.value)}
                                            slotProps={{
                                                input: {
                                                    startAdornment: <InsertLinkRoundedIcon
                                                        sx={{mr: 1, color: 'action.active'}}/>,
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid size={{xs: 12}}>
                                        <StyledButton onClick={handleExtractArticle} variant="outlined" color="primary"
                                                      startIcon={<ArticleIcon/>}>
                                            Extract Article
                                        </StyledButton>
                                    </Grid>
                                    <Grid size={{xs: 12}}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={isMobile ? 5 : 10}
                                            label="Article Content"
                                            value={article}
                                            onChange={(e) => setArticle(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant="h6">Post Settings</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid size={{xs: 12}}>
                                        <FormControl component="fieldset" fullWidth>
                                            <FormLabel component="legend">Languages</FormLabel>
                                            <Grid container spacing={2}>
                                                {['English', 'Russian', 'Azerbaijani'].map((lang) => (
                                                    <Grid size={{xs: 12, sm: 4}} key={lang}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={languages.includes(lang)}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) setLanguages([...languages, lang]);
                                                                        else setLanguages(languages.filter(l => l !== lang));
                                                                    }}
                                                                />
                                                            }
                                                            label={lang}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                    <Grid size={{xs: 12}}>
                                        <FormControl fullWidth>
                                            <InputLabel>Tones (up to 3)</InputLabel>
                                            <Select
                                                multiple
                                                value={tones}
                                                onChange={(e) => setTones(e.target.value.slice(0, 3))}
                                                renderValue={(selected) => selected.join(', ')}
                                            >
                                                {TONES.map((t) => (
                                                    <MenuItem key={t} value={t}>
                                                        <Checkbox checked={tones.indexOf(t) > -1}/>
                                                        <ListItemText primary={t}/>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid size={{xs: 12}}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Platform</FormLabel>
                                            <RadioGroup value={platform} onChange={(e) => setPlatform(e.target.value)}>
                                                <Grid container spacing={2}>
                                                    {['Facebook', 'Twitter', 'Telegram'].map((plat) => (
                                                        <Grid size={{xs: 12, sm: 4}} key={plat}>
                                                            <FormControlLabel value={plat} control={<Radio/>}
                                                                              label={plat}/>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Box sx={{mt: 3}}>
                            <StyledButton type="submit" variant="contained" color="primary" startIcon={<PostAddIcon/>}>
                                Generate Posts
                            </StyledButton>
                        </Box>
                    </StyledForm>

                    <Box sx={{mt: 4}}>
                        <AnimatePresence>
                            {generatedPosts.length > 0 ? (
                                <motion.div
                                    initial={{opacity: 0, scale: 0.9}}
                                    animate={{opacity: 1, scale: 1}}
                                    exit={{opacity: 0, scale: 0.9}}
                                    transition={{duration: 0.3}}
                                >
                                    <Typography variant="h5" gutterBottom>Generated Posts</Typography>
                                    {Array.isArray(generatedPosts) && generatedPosts.map((post, index) => (
                                        <Grow
                                            in={true}
                                            style={{transformOrigin: '0 0 0'}}
                                            timeout={300 + index * 100}
                                            key={post.id}
                                        >
                                            <Grid size={{xs: 12, sm: 6, md: 4}}>
                                                <StyledCard key={post.id}>
                                                    <CardContent>
                                                        <Typography variant="h6"
                                                                    gutterBottom>{post.platform} - {post.language}</Typography>
                                                        <Typography variant="body2">{post.content}</Typography>
                                                    </CardContent>
                                                    {post.platform === 'Facebook' && (
                                                        facebookPages.length > 0 ? (
                                                            <FormControl fullWidth margin="normal">
                                                                <InputLabel>Select Facebook Page</InputLabel>
                                                                <Select
                                                                    value={selectedPage}
                                                                    onChange={(e) => setSelectedPage(e.target.value)}
                                                                >
                                                                    {facebookPages.map((page) => (
                                                                        <MenuItem key={page.id} value={page.id}>
                                                                            {page.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        ) : (
                                                            <Typography color="error">No Facebook pages available.
                                                                Please link your Facebook account in
                                                                settings.</Typography>
                                                        )
                                                    )}
                                                    <CardActions sx={{mt: 'auto', justifyContent: 'space-between'}}>
                                                    <Button size="small" onClick={() => handlePostNow(post.id)}
                                                            startIcon={<SendIcon/>}
                                                            disabled={post.platform === 'Facebook' && !selectedPage}>Post
                                                        Now
                                                    </Button>
                                                    <Button size="small" onClick={() => handleSchedulePost(post.id)}
                                                            startIcon={<ScheduleIcon/>}>Schedule</Button>
                                                    <IconButton
                                                        onClick={() => handleCopyToClipboard(post.content)}
                                                        aria-label="copy to clipboard"
                                                    >
                                                        <ContentCopyIcon/>
                                                    </IconButton>
                                                </CardActions>
                                                    {copySuccess && (
                                                        <Typography variant="caption" color="success.main"
                                                                    sx={{textAlign: 'center', mt: 1}}>
                                                            {copySuccess}
                                                        </Typography>
                                                    )}
                                                </StyledCard>
                                            </Grid>
                                        </Grow>
                                    ))}
                                </motion.div>
                            ) : (
                                <motion.div
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    transition={{duration: 0.3}}
                                >
                                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', my: 4}}>
                                        <EmptyStateIllustration/>
                                        <Typography variant="h6" sx={{mt: 2}}>No posts generated yet</Typography>
                                        <Typography variant="body2" sx={{mt: 1}}>
                                            Enter an article URL and click "Generate Posts" to get started
                                        </Typography>
                                    </Box>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </Box>

                    <Dialog
                        open={scheduleDialogOpen}
                        onClose={handleCloseScheduleDialog}
                        TransitionComponent={Slide}
                        TransitionProps={{direction: "up"}}
                    >
                        <DialogTitle>Schedule Post</DialogTitle>
                        <DialogContent>
                            <StaticDateTimePicker
                                label="Schedule Date & Time"
                                value={utcToZonedTime(scheduledDate, userTimezone)}
                                onChange={(newValue) => setScheduledDate(newValue)}
                                slotProps={{
                                    actionBar: {actions: []},
                                    textField: {fullWidth: true}
                                }}
                                timezone={userTimezone}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseScheduleDialog}>Cancel</Button>
                            <Button onClick={handleConfirmSchedule} color="primary">Schedule</Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        open={notification.open}
                        autoHideDuration={6000}
                        onClose={hideNotification}
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        TransitionComponent={Slide}
                        TransitionProps={{direction: "down"}}
                    >
                        <MuiAlert onClose={hideNotification} severity={notification.severity} elevation={6}
                                  variant="filled">
                            {notification.message}
                        </MuiAlert>
                    </Snackbar>
                </StyledPaper>
            </Fade>
        </LocalizationProvider>)
    );
}

export default PostGenerator;