import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate, useLocation} from 'react-router-dom';
import {Box, CssBaseline} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {ThemeProvider} from './ThemeContext';
import Header from './components/Header';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import PostGenerator from './components/PostGenerator';
import InstagramPost from './components/InstagramPost';
import YouTubeUpload from './components/YouTubeUpload';
import ScheduledPosts from './components/ScheduledPosts';
import History from './components/History';
import Analytics from './components/Analytics';
import Settings from './components/Settings';
import ErrorBoundary from './components/ErrorBoundary';
import QuickActions from './components/QuickActions';
import Signup from './components/Signup';
import ResetPassword from './components/ResetPassword';
import ActivateAccount from './components/ActivateAccount';
import {GoogleOAuthProvider} from '@react-oauth/google';

const MainContent = ({children}) => {
    const location = useLocation();
    const fullWidthPages = ['/login', '/signup', '/reset-password', '/activate'];
    const isFullWidthPage = fullWidthPages.includes(location.pathname) || location.pathname.startsWith('/reset-password/');

    return (
        <Box component="main" sx={{
            flexGrow: 1,
            p: isFullWidthPage ? 0 : 3,
            ml: isFullWidthPage ? 0 : '64px',
            mt: isFullWidthPage ? 0 : '64px',
            width: isFullWidthPage ? '100%' : 'auto',
            height: isFullWidthPage ? '100vh' : 'auto',
        }}>
            {children}
        </Box>
    );
};

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

    const ProtectedRoute = ({children}) => {
        return isAuthenticated ? children : <Navigate to="/login"/>;
    };

    return (
        <ErrorBoundary>
            <ThemeProvider>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CssBaseline/>
                        <Router>
                            <Box sx={{display: 'flex'}}>
                                {isAuthenticated && (
                                    <Header isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>
                                )}
                                <MainContent>
                                    <Routes>
                                        <Route path="/login"
                                               element={<Login setIsAuthenticated={setIsAuthenticated}/>}/>
                                        <Route path="/signup" element={<Signup setIsAuthenticated={setIsAuthenticated} />} />
                                        <Route path="/activate/:token" element={<ActivateAccount />} />
                                        <Route path="/reset-password/:token" element={<ResetPassword/>}/>
                                        <Route path="/" element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
                                        <Route path="/post_generator"
                                               element={<ProtectedRoute><PostGenerator/></ProtectedRoute>}/>
                                        <Route path="/instagram_post"
                                               element={<ProtectedRoute><InstagramPost/></ProtectedRoute>}/>
                                        <Route path="/youtube_upload"
                                               element={<ProtectedRoute><YouTubeUpload/></ProtectedRoute>}/>
                                        <Route path="/scheduled_posts"
                                               element={<ProtectedRoute><ScheduledPosts/></ProtectedRoute>}/>
                                        <Route path="/history" element={<ProtectedRoute><History/></ProtectedRoute>}/>
                                        <Route path="/analytics"
                                               element={<ProtectedRoute><Analytics/></ProtectedRoute>}/>
                                        <Route path="/settings" element={<ProtectedRoute><Settings/></ProtectedRoute>}/>
                                        <Route path="*" element={<Navigate to="/" replace/>}/>
                                    </Routes>
                                    {isAuthenticated && <QuickActions/>}
                                </MainContent>
                            </Box>
                        </Router>
                    </LocalizationProvider>
                </GoogleOAuthProvider>
            </ThemeProvider>
        </ErrorBoundary>
    );
}

export default App;