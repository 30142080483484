import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Typography,
    Box,
    CircularProgress,
    Stack,
    Card as MuiCard,
    Alert,
    Fade,
    InputAdornment,
    IconButton,
    Snackbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axiosInstance from '../api/axiosConfig';
import { useNotification } from './useNotification';
import TemplateFrame from './TemplateFrame';
import { useTheme } from '../ThemeContext';
import { SitemarkIcon } from './CustomIcons';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PasswordRequirements from './PasswordRequirements';

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '450px',
    },
    boxShadow:
        'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    ...theme.applyStyles('dark', {
        boxShadow:
            'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    }),
}));

const ResetPasswordContainer = styled(Stack)(({ theme }) => ({
    height: '100%',
    padding: 20,
    backgroundImage:
        'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
        backgroundImage:
            'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
}));

function ResetPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const { token } = useParams();
    const navigate = useNavigate();
    const { notification, showNotification, hideNotification } = useNotification();
    const { mode, toggleColorMode } = useTheme();

    useEffect(() => {
        const validateToken = async () => {
            try {
                const response = await axiosInstance.get(`/api/reset-password/${token}`);
                if (response.data.message === "Token is valid") {
                    setIsTokenValid(true);
                }
            } catch (error) {
                showNotification('Invalid or expired token. Please request a new password reset.', 'error');
                navigate('/login');
            } finally {
                setIsLoading(false);
            }
        };

        validateToken();
    }, [token, navigate, showNotification]);

    const validatePassword = (password) => {
        const requirements = [
            password.length >= 8,
            /\d/.test(password),
            /[a-z]/.test(password),
            /[A-Z]/.test(password),
        ];
        return requirements.every(Boolean) ? "" : "Password does not meet all requirements";
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const passwordError = validatePassword(newPassword);
        if (passwordError) {
            showNotification(passwordError, 'error');
            return;
        }
        if (newPassword !== confirmPassword) {
            showNotification('Passwords do not match', 'error');
            return;
        }

        setIsLoading(true);
        try {
            const response = await axiosInstance.post(`/api/reset-password/${token}`, { new_password: newPassword });
            showNotification(response.data.message, 'success');
            navigate('/login');
        } catch (error) {
            showNotification(error.response?.data?.message || 'An error occurred', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        (<TemplateFrame toggleColorMode={toggleColorMode} mode={mode}>
            <ResetPasswordContainer direction="column" justifyContent="space-between">
                <Card>
                    <SitemarkIcon />
                    <Typography variant="h4" component="h1" gutterBottom>
                        Reset Password
                    </Typography>
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                            <CircularProgress />
                        </Box>
                    ) : isTokenValid ? (
                        <Fade in={true}>
                            <Box component="form" onSubmit={handleSubmit} noValidate>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="new_password"
                                    label="New Password"
                                    type={showPassword ? "text" : "password"}
                                    id="new_password"
                                    autoComplete="new-password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    error={!!passwordError}
                                    helperText={passwordError}
                                    slotProps={{
                                        input: {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleTogglePasswordVisibility}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }
                                    }}
                                />
                                <PasswordRequirements password={newPassword} />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="confirm_password"
                                    label="Confirm New Password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    id="confirm_password"
                                    autoComplete="new-password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    error={!!confirmPasswordError}
                                    helperText={confirmPasswordError}
                                    slotProps={{
                                        input: {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle confirm password visibility"
                                                        onClick={handleToggleConfirmPasswordVisibility}
                                                        edge="end"
                                                    >
                                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }
                                    }}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={isLoading}
                                >
                                    {isLoading ? <CircularProgress size={24} /> : 'Reset Password'}
                                </Button>
                            </Box>
                        </Fade>
                    ) : (
                        <Alert severity="error">
                            Invalid or expired token. Please request a new password reset.
                        </Alert>
                    )}
                    <Button
                        variant="text"
                        onClick={() => navigate('/login')}
                        sx={{ mt: 2 }}
                    >
                        Back to Login
                    </Button>
                </Card>
            </ResetPasswordContainer>
            <Snackbar
                open={notification.open}
                autoHideDuration={6000}
                onClose={hideNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={hideNotification} severity={notification.severity} sx={{ width: '100%' }}>
                    {notification.message}
                </Alert>
            </Snackbar>
        </TemplateFrame>)
    );
}

export default ResetPassword;