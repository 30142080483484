import React from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ToggleColorMode from './ToggleColorMode';
import getSignInTheme from '../theme/getSignInTheme';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
    borderBottom: '1px solid',
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    backgroundImage: 'none',
    zIndex: theme.zIndex.drawer + 1,
    flex: '0 0 auto',
}));

function TemplateFrame({
                           mode,
                           toggleColorMode,
                           children,
                       }) {
    const signInTheme = createTheme(getSignInTheme(mode));

    return (
        <ThemeProvider theme={signInTheme}>
            <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
                <StyledAppBar>
                    <Toolbar
                        variant="dense"
                        disableGutters
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: '8px 12px',
                            alignItems: 'right'
                        }}
                    >
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'right' }}>
                            <ToggleColorMode
                                mode={mode}
                                toggleColorMode={toggleColorMode}
                            />
                        </Box>
                    </Toolbar>
                </StyledAppBar>
                <Box sx={{ flex: '1 1', overflow: 'auto' }}>{children}</Box>
            </Box>
        </ThemeProvider>
    );
}

export default TemplateFrame;