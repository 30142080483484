import React, { useState, useEffect } from 'react';
import {
    TextField, Button, Paper, Typography, LinearProgress, Snackbar,
    styled, IconButton, Tooltip, Box, FormControl, InputLabel, Input
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import MuiAlert from '@mui/material/Alert';
import { motion, AnimatePresence } from 'framer-motion';
import { Fade, Grow, Slide } from '@mui/material';
import axiosInstance from '../api/axiosConfig';
import { useNotification } from './useNotification';
import { useNavigate, useLocation } from 'react-router-dom';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TitleIcon from '@mui/icons-material/Title';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    maxWidth: '800px',
    margin: '20px auto',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
}));

const StyledProgressContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const InputWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
}));

function YouTubeUpload() {
    const [videoFile, setVideoFile] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { notification, showNotification, hideNotification } = useNotification();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        checkYouTubeAuthStatus();

        // Check if redirected from OAuth2 callback
        const urlParams = new URLSearchParams(location.search);
        if (urlParams.get('code')) {
            handleOAuth2Callback(urlParams);
        }
    }, [location]);

    const checkYouTubeAuthStatus = async () => {
        try {
            const response = await axiosInstance.get('/api/youtube_auth_status');
            setIsAuthenticated(response.data.authenticated);
        } catch (error) {
            console.error('Error checking YouTube auth status:', error);
            showNotification('Failed to check YouTube authentication status', 'error');
        }
    };

    const handleAuthenticate = async () => {
        try {
            const response = await axiosInstance.get('/api/youtube_auth');
            window.location.href = response.data.auth_url;
        } catch (error) {
            console.error('Error initiating YouTube authentication:', error);
            showNotification('Failed to initiate YouTube authentication', 'error');
        }
    };

    const handleOAuth2Callback = async (urlParams) => {
        try {
            const code = urlParams.get('code');
            const state = urlParams.get('state');
            const response = await axiosInstance.get(`/api/youtube_oauth2callback?code=${code}&state=${state}`);
            if (response.data.message === "YouTube authentication successful") {
                setIsAuthenticated(true);
                showNotification('YouTube authentication successful', 'success');
            }
        } catch (error) {
            console.error('Error handling OAuth2 callback:', error);
            showNotification('Failed to complete YouTube authentication', 'error');
        }
    };

    const handleFileChange = (event) => {
        setVideoFile(event.target.files[0]);
    };

    const handleUpload = async (event) => {
        event.preventDefault();
        setUploading(true);
        setUploadProgress(0);

        const formData = new FormData();
        formData.append('video', videoFile);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('tags', tags);

        try {
            const response = await axiosInstance.post('/api/youtube_upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                }
            });

            showNotification('Video uploaded successfully to YouTube!', 'success');
            console.log('Upload successful:', response.data);
        } catch (error) {
            console.error('Error uploading video:', error);
            showNotification('Failed to upload video to YouTube. Please try again.', 'error');
        } finally {
            setUploading(false);
        }
    };

    if (!isAuthenticated) {
        return (
            <StyledPaper elevation={3}>
                <Typography variant="h4" gutterBottom>
                    <YouTubeIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                    YouTube Upload
                </Typography>
                <Typography variant="body1" gutterBottom>You need to authenticate with YouTube before uploading videos.</Typography>
                <StyledButton onClick={handleAuthenticate} variant="contained" color="primary" startIcon={<LockOpenIcon />}>
                    Authenticate with YouTube
                </StyledButton>
            </StyledPaper>
        );
    }

    return (
        <Fade in={true} timeout={300}>
            <StyledPaper elevation={3}>
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <Typography variant="h4" gutterBottom>
                        <YouTubeIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                        Upload Video to YouTube
                    </Typography>
                </motion.div>

                <Grow in={true} timeout={300}>
                    <form onSubmit={handleUpload}>
                        <Grid container spacing={3}>
                            <Grid size={{ xs: 12 }}>
                                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                                    <input
                                        accept="video/*"
                                        style={{ display: 'none' }}
                                        id="raised-button-file"
                                        type="file"
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="raised-button-file">
                                        <StyledButton variant="contained" component="span" color="secondary" startIcon={<CloudUploadIcon />}>
                                            Select Video File
                                        </StyledButton>
                                    </label>
                                </motion.div>
                                {videoFile && <Typography variant="body2" sx={{ mt: 1 }}>{videoFile.name}</Typography>}
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                                <InputWrapper>
                                    <TitleIcon sx={{ mr: 1, color: 'action.active' }} />
                                    <TextField
                                        fullWidth
                                        label="Title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                    />
                                    <Tooltip title="Enter a catchy title for your video">
                                        <IconButton size="small">
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputWrapper>
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                                <InputWrapper>
                                    <DescriptionIcon sx={{ mr: 1, color: 'action.active' }} />
                                    <TextField
                                        fullWidth
                                        label="Description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        multiline
                                        rows={4}
                                    />
                                    <Tooltip title="Provide a detailed description of your video">
                                        <IconButton size="small">
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputWrapper>
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                                <InputWrapper>
                                    <LocalOfferIcon sx={{ mr: 1, color: 'action.active' }} />
                                    <TextField
                                        fullWidth
                                        label="Tags (comma-separated)"
                                        value={tags}
                                        onChange={(e) => setTags(e.target.value)}
                                    />
                                    <Tooltip title="Add relevant tags to help users find your video">
                                        <IconButton size="small">
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputWrapper>
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                                    <StyledButton type="submit" variant="contained" color="primary" disabled={!videoFile || uploading} startIcon={<CloudUploadIcon />}>
                                        Upload to YouTube
                                    </StyledButton>
                                </motion.div>
                            </Grid>
                        </Grid>
                    </form>
                </Grow>

                <AnimatePresence>
                    {uploading && (
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                        >
                            <StyledProgressContainer>
                                <Typography variant="body2" gutterBottom>Upload Progress</Typography>
                                <LinearProgress variant="determinate" value={uploadProgress} />
                                <Typography variant="body2" align="center" sx={{ mt: 1 }}>{uploadProgress}%</Typography>
                            </StyledProgressContainer>
                        </motion.div>
                    )}
                </AnimatePresence>

                <Snackbar
                    open={notification.open}
                    autoHideDuration={6000}
                    onClose={hideNotification}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    TransitionComponent={Slide}
                    TransitionProps={{ direction: "down" }}
                >
                    <MuiAlert onClose={hideNotification} severity={notification.severity} elevation={6} variant="filled">
                        {notification.message}
                    </MuiAlert>
                </Snackbar>
            </StyledPaper>
        </Fade>
    );
}

export default YouTubeUpload;