import React, {useState, useEffect, useRef} from 'react';
import {
    TextField, Button, Paper, Typography, Card, CardContent, CardMedia, CardActions,
    FormControl, InputLabel, Select, MenuItem, Snackbar, Slider, styled,
    Box, Accordion, AccordionSummary, AccordionDetails, CardActionArea, Checkbox, ListItemText,
    ToggleButton, ToggleButtonGroup
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import MuiAlert from '@mui/material/Alert';
import { Fade, Zoom } from '@mui/material';
import axiosInstance from "../api/axiosConfig";
import { useNotification } from './useNotification';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkIcon from '@mui/icons-material/Link';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import ImageIcon from '@mui/icons-material/Image';
import PreviewIcon from '@mui/icons-material/Preview';
import PostAddIcon from '@mui/icons-material/PostAdd';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import CloseIcon from '@mui/icons-material/Close';
import MoodIcon from '@mui/icons-material/Mood';
import LanguageIcon from '@mui/icons-material/Language';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import LoadingIndicator from './LoadingIndicator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(3),
    maxWidth: '1000px',
    margin: '20px auto',
}));

const StyledCard = styled(Card)(({theme, selected}) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    border: selected ? `2px solid ${theme.palette.primary.main}` : 'none',
    '&:hover': {
        boxShadow: theme.shadows[5],
    },
}));

const SelectionIcon = styled(Checkbox)(({theme}) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.primary.main,
    background: 'rgba(255, 255, 255, 0.7)',
    borderRadius: '50%',
    padding: theme.spacing(0.5),
}));

const StyledCardMedia = styled(CardMedia)(({theme}) => ({
    paddingTop: '56.25%', // 16:9 aspect ratio
}));

const StyledButton = styled(Button)(({theme}) => ({
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
}));

const FullPreviewOverlayBar = styled('div')(({ theme, position, height }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    height: height,
    backgroundColor: 'rgba(0,0,0,0.7)',
    [position === 'top' ? 'top' : 'bottom']: 0,
}));

const FullPreviewOverlay = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
}));

const FullPreviewContent = styled('div')(({ theme }) => ({
    position: 'relative',
    maxWidth: '90%',
    maxHeight: '90%',
    width: 'auto',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const FullPreviewImage = styled('img')({
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 100px)', // Adjust based on your needs
    objectFit: 'contain',
});

const FullPreviewCaption = styled('div')(({ theme, customPadding }) => ({
    position: 'absolute',
    left: '5%',
    right: '5%',
    width: '90%',
    color: theme.palette.common.white,
    padding: customPadding || theme.spacing(2), // Use custom padding if provided, otherwise use default
    textAlign: 'center',
    wordWrap: 'break-word',
    overflowY: 'auto',
    maxHeight: '30%',
    zIndex: 1,
}));

const TONES = [
    'Analytical', 'Apologetic', 'Assertive', 'Authoritative', 'Avant-garde',
    'Casual', 'Cautionary', 'Celebratory', 'Compassionate', 'Confident',
    'Congratulatory', 'Controversial', 'Conversational', 'Curious', 'Cynical',
    'Dramatic', 'Educational', 'Empathetic', 'Encouraging', 'Enigmatic',
    'Enthusiastic', 'Ethereal', 'Excited', 'Formal', 'Friendly',
    'Futuristic', 'Grateful', 'Hopeful', 'Humble', 'Humorous',
    'Informative', 'Inspirational', 'Introspective', 'Ironic', 'Irreverent',
    'Melancholic', 'Motivational', 'Mysterious', 'Neutral', 'Nostalgic',
    'Optimistic', 'Patriotic', 'Persuasive', 'Pessimistic', 'Philosophical',
    'Poetic', 'Professional', 'Provocative', 'Rebellious', 'Reflective',
    'Romantic', 'Sarcastic', 'Satirical', 'Sensational', 'Sensual',
    'Serious', 'Shocked', 'Skeptical', 'Stoic', 'Sympathetic',
    'Urgent', 'Whimsical', 'Witty', 'Zen'
];

const LANGUAGES = ['English', 'Russian', 'Azerbaijani'];

const ParagraphText = ({text}) => {
    const paragraphs = text.split('\n\n');
    return (
        <>
            {paragraphs.map((paragraph, index) => (
                <Typography key={index} variant="body1" component="p">
                    {paragraph}
                </Typography>
            ))}
        </>
    );
};

function InstagramPost() {
    const [articleUrl, setArticleUrl] = useState('');
    const [extractedContent, setExtractedContent] = useState(null);
    const [generatedPost, setGeneratedPost] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [postType, setPostType] = useState('single');
    const {notification, showNotification, hideNotification} = useNotification();
    const [overlayedImages] = useState({});
    const [customizations, setCustomizations] = useState({});
    const [availableFonts] = useState(['Arial', 'Helvetica', 'Times New Roman', 'Courier', 'Verdana', 'Georgia', 'Palatino', 'Garamond', 'Bookman', 'Comic Sans MS', 'Trebuchet MS', 'Arial Black', 'Impact']);
    const [fullPreview, setFullPreview] = useState(null);
    const [tone, setTone] = useState('Neutral');
    const [isLoading, setIsLoading] = useState(false);
    const [contentExpanded, setContentExpanded] = useState(false);
    const [language, setLanguage] = useState('');
    const [tones, setTones] = useState([]);
    const [defaultSettings, setDefaultSettings] = useState({});
    const [overlayPosition, setOverlayPosition] = useState('bottom');

    useEffect(() => {
        fetchUserSettings();
    }, []);

    const handleOverlayPositionChange = (event, newPosition) => {
        if (newPosition !== null) {
            console.log("Overlay position changed to:", newPosition);  // Add this line
            setOverlayPosition(newPosition);
        }
    };

    const fetchUserSettings = async () => {
        try {
            const response = await axiosInstance.get('/api/settings');
            if (response.data && response.data.default_language) {
                setDefaultSettings(response.data);
                setLanguage(response.data.default_language);
            } else {
                console.log('User settings not available or incomplete');
                setLanguage('English');  // Set a default value
            }
        } catch (error) {
            console.error('Error fetching user settings:', error);
            showNotification('Failed to fetch user settings', 'error');
            setLanguage('English');  // Set a default value
        }
    };

    const handleImageSelection = (image) => {
        setSelectedImages(prev =>
            prev.includes(image) ? prev.filter(i => i !== image) : [...prev, image]
        );
    };

    const handleContentAccordionChange = (event, isExpanded) => {
        setContentExpanded(isExpanded);
    };

    const handleFontChange = (imageUrl, font) => {
        setCustomizations(prev => ({
            ...prev,
            [imageUrl]: {...prev[imageUrl], fontFamily: font}
        }));
    };

    const handleFontSizeChange = (imageUrl, size) => {
        setCustomizations(prev => ({
            ...prev,
            [imageUrl]: {...prev[imageUrl], fontSize: size}
        }));
    };

    const handlePreview = async (ic) => {
        setIsLoading(true);
        try {
            console.log("Generating preview with position:", overlayPosition);
            const response = await axiosInstance.post('/api/generate_overlay_preview', {
                image_url: ic.image_url,
                caption: ic.caption,
                font_family: customizations[ic.image_url]?.fontFamily || 'Arial',
                font_size: customizations[ic.image_url]?.fontSize || 40,
                position: overlayPosition
            }, {
                responseType: 'blob'
            });
            const imageUrl = URL.createObjectURL(response.data);
            setFullPreview({...ic, previewUrl: imageUrl, position: overlayPosition});
        } catch (error) {
            console.error('Error generating preview:', error);
            showNotification('Failed to generate preview. Please try again.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const FullPreview = ({ image, onClose }) => {
        const captionRef = useRef(null);
        const [overlayHeight, setOverlayHeight] = useState('30%');
        const customCaptionPadding = '10px'; // You can adjust this value as needed

        useEffect(() => {
            if (captionRef.current) {
                const captionHeight = captionRef.current.offsetHeight;
                setOverlayHeight(`${captionHeight + 5}px`); // 20px higher than the caption
            }
        }, [image, captionRef]);

        return (
            <FullPreviewOverlay>
                <FullPreviewContent>
                    <FullPreviewImage
                        src={`/api/proxy_image/${encodeURIComponent(image.image_url)}`}
                        alt="Full Preview"
                    />
                    <FullPreviewOverlayBar position={image.position} height={overlayHeight} />
                    <FullPreviewCaption
                        ref={captionRef}
                        customPadding={customCaptionPadding}
                        style={{
                            fontFamily: customizations[image.image_url]?.fontFamily || 'Arial',
                            fontSize: `${customizations[image.image_url]?.fontSize || 16}px`,
                            top: image.position === 'top' ? 0 : 'auto',
                            bottom: image.position === 'top' ? 'auto' : 0,
                            backgroundColor: 'transparent',
                        }}
                    >
                        {image.caption}
                    </FullPreviewCaption>
                </FullPreviewContent>
                <StyledButton
                    onClick={onClose}
                    variant="contained"
                    color="secondary"
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                    }}
                >
                    <CloseIcon />
                </StyledButton>
            </FullPreviewOverlay>
        );
    };

    useEffect(() => {
        return () => {
            Object.values(overlayedImages).forEach(url => URL.revokeObjectURL(url));
        };
    }, [overlayedImages]);

    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }

    const handleExtractArticle = async () => {
        if (!isValidUrl(articleUrl)) {
            showNotification('Please enter a valid URL', 'error');
            return;
        }
        setIsLoading(true);
        try {
            const response = await axiosInstance.post('/api/extract_article_for_instagram', {article_url: articleUrl});
            setExtractedContent(response.data);
            showNotification('Article extracted successfully', 'success');
        } catch (error) {
            console.error('Error extracting article:', error);
            let errorMessage = 'Failed to extract article. Please try again.';

            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);

                if (error.response.data && error.response.data.error) {
                    errorMessage = error.response.data.error;
                } else if (error.response.status === 400) {
                    errorMessage = 'Invalid article URL. Please check and try again.';
                }
            } else if (error.request) {
                console.error('No response received:', error.request);
                errorMessage = 'No response received from the server. Please try again later.';
            } else {
                console.error('Error setting up request:', error.message);
            }

            showNotification(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleToneChange = (event) => {
        const selectedTones = event.target.value;
        setTones(selectedTones.slice(0, 3)); // Limit to 3 tones
    };

    const handleGeneratePost = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.post('/api/generate_instagram_post', {
                text: extractedContent.text,
                images: selectedImages,
                language: language,
                tones: tones
            });
            setGeneratedPost(response.data);
            showNotification('Instagram post generated successfully', 'success');
        } catch (error) {
            console.error('Error generating Instagram post:', error);
            showNotification('Failed to generate Instagram post. Please try again.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePostToInstagram = async () => {
        setIsLoading(true);
        try {
            const imageUrls = generatedPost.image_captions.map(ic => ic.image_url);
            const captions = generatedPost.image_captions.map(ic => ic.caption);

            const response = await axiosInstance.post('/api/post_to_instagram', {
                post_type: postType,
                description: generatedPost.description,
                image_urls: imageUrls,
                captions: captions,
                font_family: customizations[imageUrls[0]]?.fontFamily || 'Arial',
                font_size: customizations[imageUrls[0]]?.fontSize || 16,
                overlay_position: overlayPosition
            });

            if (response.data.success) {
                showNotification('Posted to Instagram successfully!', 'success');
            } else {
                throw new Error(response.data.error || 'Unknown error occurred');
            }
        } catch (error) {
            console.error('Error posting to Instagram:', error);
            showNotification(`Failed to post to Instagram: ${error.message}`, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        (<Fade in={true} timeout={300}>
            <StyledPaper elevation={3}>
                <LoadingIndicator open={isLoading} message="Processing..."/>
                <Box sx={{mb: 4}}>
                    <Typography variant="h4" gutterBottom>
                        <InstagramIcon sx={{mr: 1, verticalAlign: 'middle'}}/>
                        Instagram Post Generator
                    </Typography>
                </Box>

                <Grid container spacing={3}>
                    <Grid size={{xs: 12}}>
                        <TextField
                            fullWidth
                            label="Article URL"
                            value={articleUrl}
                            onChange={(e) => setArticleUrl(e.target.value)}
                            margin="normal"
                            slotProps={{
                                input: {
                                    startAdornment: <LinkIcon sx={{mr: 1, color: 'action.active'}}/>,
                                }
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Zoom in={true} timeout={300}>
                            <StyledButton onClick={handleExtractArticle} variant="contained" color="primary"
                                          startIcon={<InsertLinkRoundedIcon/>}>
                                Extract Article
                            </StyledButton>
                        </Zoom>
                    </Grid>
                </Grid>

                {extractedContent && (
                    <Box sx={{mt: 4}}>
                        <Accordion
                            expanded={contentExpanded}
                            onChange={handleContentAccordionChange}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="extracted-content-panel"
                                id="extracted-content-header"
                            >
                                <Typography variant="h5">
                                    <ImageIcon sx={{mr: 1, verticalAlign: 'middle'}}/>
                                    Extracted Content
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ParagraphText text={extractedContent.text}/>
                            </AccordionDetails>
                        </Accordion>
                        <Grid container spacing={3}>
                            {extractedContent.images.map((image, index) => (
                                <Grid size={{xs: 12, sm: 6, md: 4}} key={index}>
                                    <StyledCard selected={selectedImages.includes(image)}>
                                        <CardActionArea onClick={() => handleImageSelection(image)}>
                                            <StyledCardMedia
                                                image={`/api/proxy_image/${encodeURIComponent(image)}`}
                                                title={`Image ${index + 1}`}
                                            />
                                            <SelectionIcon
                                                checked={selectedImages.includes(image)}
                                                icon={<RadioButtonUncheckedIcon/>}
                                                checkedIcon={<CheckCircleIcon/>}
                                            />
                                        </CardActionArea>
                                    </StyledCard>
                                </Grid>
                            ))}
                        </Grid>
                        <Box sx={{mt: 3}}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>
                                    <LanguageIcon sx={{mr: 1, verticalAlign: 'middle'}}/>
                                    Language
                                </InputLabel>
                                <Select
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                >
                                    <MenuItem value="English">English</MenuItem>
                                    <MenuItem value="Russian">Russian</MenuItem>
                                    <MenuItem value="Azerbaijani">Azerbaijani</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{mt: 3}}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>
                                    <MoodIcon sx={{mr: 1, verticalAlign: 'middle'}}/>
                                    Tones (up to 3)
                                </InputLabel>
                                <Select
                                    multiple
                                    value={tones}
                                    onChange={handleToneChange}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {TONES.map((tone) => (
                                        <MenuItem key={tone} value={tone}>
                                            <Checkbox checked={tones.indexOf(tone) > -1}/>
                                            <ListItemText primary={tone}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{mt: 3}}>
                            <StyledButton onClick={handleGeneratePost} variant="contained" color="primary"
                                          startIcon={<PostAddIcon/>}>
                                Generate Instagram Post
                            </StyledButton>
                        </Box>
                    </Box>
                )}

                {generatedPost && (
                    <Box sx={{mt: 4}}>
                        <Typography variant="h5" gutterBottom>
                            <InstagramIcon sx={{mr: 1, verticalAlign: 'middle'}}/>
                            Generated Instagram Post
                        </Typography>
                        <Typography variant="body1" sx={{
                            marginBottom: "16px"
                        }}>{generatedPost.description}</Typography>
                        <Grid container spacing={3}>
                            {generatedPost.image_captions.map((ic, index) => (
                                <Grid size={{xs: 12, sm: 6, md: 4}} key={index}>
                                    <StyledCard>
                                        <StyledCardMedia
                                            image={`/api/proxy_image/${encodeURIComponent(ic.image_url)}`}
                                            title={`Image ${index + 1}`}
                                        />
                                        <CardContent>
                                            <Typography variant="body2" sx={{
                                                marginBottom: "16px"
                                            }}>{ic.caption}</Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                <AspectRatioIcon sx={{mr: 1, verticalAlign: 'middle'}}/>
                                                Post Type: {ic.post_type}
                                            </Typography>
                                            <FormControl fullWidth margin="normal">
                                                <InputLabel>
                                                    <FontDownloadIcon sx={{mr: 1, verticalAlign: 'middle'}}/>
                                                    Font
                                                </InputLabel>
                                                <Select
                                                    value={customizations[ic.image_url]?.fontFamily || 'Arial'}
                                                    onChange={(e) => handleFontChange(ic.image_url, e.target.value)}
                                                >
                                                    {availableFonts.map((font) => (
                                                        <MenuItem key={font} value={font}>{font}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Box sx={{mt: 2}}>
                                                <Typography id="font-size-slider" gutterBottom>
                                                    <FormatSizeIcon sx={{mr: 1, verticalAlign: 'middle'}}/>
                                                    Font Size
                                                </Typography>
                                                <Slider
                                                    value={customizations[ic.image_url]?.fontSize || 16}
                                                    onChange={(_, newValue) => handleFontSizeChange(ic.image_url, newValue)}
                                                    aria-labelledby="font-size-slider"
                                                    valueLabelDisplay="auto"
                                                    step={2}
                                                    marks
                                                    min={10}
                                                    max={30}
                                                />
                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                                <Typography gutterBottom>
                                                    Overlay Position
                                                </Typography>
                                                <ToggleButtonGroup
                                                    value={overlayPosition}
                                                    exclusive
                                                    onChange={handleOverlayPositionChange}
                                                    aria-label="overlay position"
                                                >
                                                    <ToggleButton value="top" aria-label="top">
                                                        <VerticalAlignTopIcon />
                                                    </ToggleButton>
                                                    <ToggleButton value="bottom" aria-label="bottom">
                                                        <VerticalAlignBottomIcon />
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Box>
                                        </CardContent>
                                        <CardActions>
                                            <StyledButton size="small" onClick={() => handlePreview(ic)}
                                                          variant="outlined" color="primary" startIcon={<PreviewIcon/>}>
                                                Show Preview
                                            </StyledButton>
                                        </CardActions>
                                    </StyledCard>
                                </Grid>
                            ))}
                        </Grid>
                        <Box sx={{mt: 3}}>
                            <FormControl fullWidth>
                                <InputLabel>
                                    <PostAddIcon sx={{mr: 1, verticalAlign: 'middle'}}/>
                                    Post Type
                                </InputLabel>
                                <Select
                                    value={postType}
                                    onChange={(e) => setPostType(e.target.value)}
                                >
                                    <MenuItem value="single">Single Image</MenuItem>
                                    <MenuItem value="carousel">Carousel (Multiple Images)</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{mt: 3}}>
                            <StyledButton onClick={handlePostToInstagram} variant="contained" color="secondary"
                                          startIcon={<InstagramIcon/>}>
                                Post to Instagram
                            </StyledButton>
                        </Box>
                    </Box>
                )}

                {fullPreview && (
                    <FullPreview
                        image={fullPreview}
                        onClose={() => setFullPreview(null)}
                    />
                )}

                <Snackbar
                    open={notification.open}
                    autoHideDuration={6000}
                    onClose={hideNotification}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    TransitionComponent={Fade}
                >
                    <MuiAlert onClose={hideNotification} severity={notification.severity} elevation={6}
                              variant="filled">
                        {notification.message}
                    </MuiAlert>
                </Snackbar>
            </StyledPaper>
        </Fade>)
    );
}

export default InstagramPost;