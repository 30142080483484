import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    CircularProgress,
    Typography,
    Box,
    Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axiosInstance from '../api/axiosConfig';
import { useNotification } from './useNotification';
import { SitemarkIcon } from './CustomIcons';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: theme.shape.borderRadius,
        boxShadow:
            'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
        padding: theme.spacing(2),
        ...theme.applyStyles('dark', {
            boxShadow:
                'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
        }),
    },
}));

function ForgotPassword({ open, handleClose }) {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [resetStatus, setResetStatus] = useState(null);
    const { showNotification } = useNotification();

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setEmailError('');
        setResetStatus(null);

        if (!email) {
            setEmailError('Email is required');
            return;
        }

        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }

        setIsLoading(true);
        try {
            const response = await axiosInstance.post('/api/forgot-password', { email });
            setResetStatus({ type: 'success', message: response.data.message });
            showNotification('Password reset email sent successfully', 'success');
            setTimeout(() => {
                handleClose();
                setEmail('');
                setResetStatus(null);
            }, 3000);
        } catch (error) {
            console.error('Error in password reset:', error);
            setResetStatus({
                type: 'error',
                message: error.response?.data?.message || 'An error occurred while processing your request'
            });
            showNotification('Failed to send password reset email', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <StyledDialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                    <SitemarkIcon />
                    <Typography variant="h5" component="div" align="center" gutterBottom>
                        Forgot Password
                    </Typography>
                </Box>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    {resetStatus && (
                        <Alert severity={resetStatus.type} sx={{ mb: 2 }}>
                            {resetStatus.message}
                        </Alert>
                    )}
                    <Typography variant="body2" gutterBottom>
                        Enter your email address and we'll send you a link to reset your password.
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!emailError}
                        helperText={emailError}
                        disabled={isLoading}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" disabled={isLoading}>
                        {isLoading ? <CircularProgress size={24} /> : 'Reset Password'}
                    </Button>
                </DialogActions>
            </form>
        </StyledDialog>
    );
}

export default ForgotPassword;