import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box, CircularProgress } from '@mui/material';
import axiosInstance from '../api/axiosConfig';
import { useNotification } from './useNotification';

function ActivateAccount() {
    const { token } = useParams();
    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const activateAccount = async () => {
            try {
                await axiosInstance.get(`/api/activate/${token}`);
                showNotification('Account activated successfully! You can now log in.', 'success');
                navigate('/login');
            } catch (error) {
                showNotification('Invalid or expired activation token.', 'error');
                navigate('/login');
            } finally {
                setIsLoading(false);
            }
        };

        activateAccount();
    }, [token, navigate, showNotification]);

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Typography variant="h6" align="center">
            Activating your account...
        </Typography>
    );
}

export default ActivateAccount;