import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function SitemarkIcon() {
    return (
        <SvgIcon sx={{ height: 21, width: 100 }}>
            <svg
                width={86}
                height={19}
                viewBox="0 0 86 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {/* Add the SVG path data for the Sitemark icon here */}
            </svg>
        </SvgIcon>
    );
}

export function FacebookIcon() {
    return (
        <SvgIcon>
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {/* Add the SVG path data for the Facebook icon here */}
            </svg>
        </SvgIcon>
    );
}

export function GoogleIcon() {
    return (
        <SvgIcon>
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {/* Add the SVG path data for the Google icon here */}
            </svg>
        </SvgIcon>
    );
}