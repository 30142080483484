import React from 'react';

const EmptyStateIllustration = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        viewBox="0 0 200 200"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <rect x="50" y="50" width="100" height="100" rx="10" />
        <line x1="50" y1="80" x2="150" y2="80" />
        <circle cx="65" cy="65" r="5" />
        <line x1="85" y1="100" x2="135" y2="100" />
        <line x1="85" y1="120" x2="135" y2="120" />
        <line x1="85" y1="140" x2="135" y2="140" />
    </svg>
);

export default EmptyStateIllustration;