import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const PasswordRequirement = ({ met, text }) => (
    <Box display="flex" alignItems="center" mb={0.5}>
        {met ? (
            <CheckCircleOutlineIcon color="success" fontSize="small" />
        ) : (
            <RadioButtonUncheckedIcon color="disabled" fontSize="small" />
        )}
        <Typography variant="body2" ml={1}>
            {text}
        </Typography>
    </Box>
);

const PasswordRequirements = ({ password }) => {
    const requirements = [
        { check: password.length >= 8, text: 'At least 8 characters long' },
        { check: /\d/.test(password), text: 'Contains at least one number' },
        { check: /[a-z]/.test(password), text: 'Contains lowercase letter' },
        { check: /[A-Z]/.test(password), text: 'Contains uppercase letter' },
    ];

    return (
        <Box mt={1}>
            <Typography variant="subtitle2" gutterBottom>
                Password Requirements:
            </Typography>
            {requirements.map((req, index) => (
                <PasswordRequirement key={index} met={req.check} text={req.text} />
            ))}
        </Box>
    );
};

export default PasswordRequirements;