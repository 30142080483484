import { createTheme, alpha } from '@mui/material/styles';

const customTheme = createTheme();

export const brand = {
    50: 'hsl(210, 100%, 95%)',
    100: 'hsl(210, 100%, 92%)',
    200: 'hsl(210, 100%, 80%)',
    300: 'hsl(210, 100%, 65%)',
    400: 'hsl(210, 98%, 48%)',
    500: 'hsl(210, 98%, 42%)',
    600: 'hsl(210, 98%, 55%)',
    700: 'hsl(210, 100%, 35%)',
    800: 'hsl(210, 100%, 16%)',
    900: 'hsl(210, 100%, 21%)',
};

export const gray = {
    50: 'hsl(220, 35%, 97%)',
    100: 'hsl(220, 30%, 94%)',
    200: 'hsl(220, 20%, 88%)',
    300: 'hsl(220, 20%, 80%)',
    400: 'hsl(220, 20%, 65%)',
    500: 'hsl(220, 20%, 42%)',
    600: 'hsl(220, 20%, 35%)',
    700: 'hsl(220, 20%, 25%)',
    800: 'hsl(220, 30%, 6%)',
    900: 'hsl(220, 35%, 3%)',
};

const getDesignTokens = (mode) => ({
    palette: {
        mode,
        primary: {
            light: brand[200],
            main: brand[400],
            dark: brand[700],
            contrastText: brand[50],
            ...(mode === 'dark' && {
                contrastText: brand[50],
                light: brand[300],
                main: brand[400],
                dark: brand[700],
            }),
        },
        secondary: {
            main: '#dc004e',
        },
        background: {
            default: mode === 'light' ? 'hsl(0, 0%, 99%)' : gray[900],
            paper: mode === 'light' ? 'hsl(220, 35%, 97%)' : 'hsl(220, 30%, 7%)',
        },
        text: {
            primary: mode === 'light' ? gray[800] : 'hsl(0, 0%, 100%)',
            secondary: mode === 'light' ? gray[600] : gray[400],
        },
        divider: mode === 'light' ? alpha(gray[300], 0.4) : alpha(gray[700], 0.6),
    },
    typography: {
        fontFamily: ['"Inter", "Roboto", "Helvetica", "Arial", sans-serif'].join(','),
        h1: {
            fontSize: customTheme.typography.pxToRem(48),
            fontWeight: 600,
            lineHeight: 1.2,
            letterSpacing: -0.5,
        },
        h2: {
            fontSize: customTheme.typography.pxToRem(36),
            fontWeight: 600,
            lineHeight: 1.2,
        },
        h3: {
            fontSize: customTheme.typography.pxToRem(30),
            lineHeight: 1.2,
        },
        h4: {
            fontSize: customTheme.typography.pxToRem(24),
            fontWeight: 600,
            lineHeight: 1.5,
        },
        h5: {
            fontSize: customTheme.typography.pxToRem(20),
            fontWeight: 600,
        },
        h6: {
            fontSize: customTheme.typography.pxToRem(18),
            fontWeight: 600,
        },
        body1: {
            fontSize: customTheme.typography.pxToRem(14),
        },
        body2: {
            fontSize: customTheme.typography.pxToRem(14),
            fontWeight: 400,
        },
    },
    shape: {
        borderRadius: 8,
    },
    shadows: [
        'none',
        mode === 'light'
            ? 'hsla(220, 30%, 5%, 0.07) 0px 4px 16px 0px, hsla(220, 25%, 10%, 0.07) 0px 8px 16px -5px'
            : 'hsla(220, 30%, 5%, 0.7) 0px 4px 16px 0px, hsla(220, 25%, 10%, 0.8) 0px 8px 16px -5px',
    ],
});

const getTheme = (mode) => {
    const tokens = getDesignTokens(mode);
    return createTheme({
        ...tokens,
        components: {
            MuiButton: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        borderRadius: theme.shape.borderRadius,
                        textTransform: 'none',
                        fontWeight: 600,
                    }),
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        borderRadius: theme.shape.borderRadius,
                    }),
                },
            },
            // Add more component customizations as needed
        },
    });
};

export default getTheme;